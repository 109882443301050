import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import myConfig  from "../configs/config";
import AuthContext from "../context/AuthContext";
import blackboard from '../assests/images/blackboard.png';
import ReactQuill, { Quill } from 'react-quill';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-quill/dist/quill.snow.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Skeleton from "@mui/material/Skeleton";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function EditCourse(){

    const [courses, setCourses] = useState(null);
    const [onCourse, setOnCourse] = useState(true);
    const [isEditStatus, setIsEditStatus] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState('');
    const [courseImage, setCourseImage] = useState(null);
    const [courseImageurl, setCourseImageurl] = useState('')

    const { CourseID } = useParams();
    const {authTokens, logoutUser, user} = useContext(AuthContext);

    // Section
    const [section, setsection] = useState([]);
    const [sectionId, setSectionId]  = useState('');
    const [title, setTitle] = useState('');
    const [descriptionsection, setDescriptionsection] = useState('');
    const [image, setImage] = useState(null);
    const [document, setDocument] = useState(null);
    const [audio, setAudio] = useState(null);
    const [video, setVideo] = useState(null);

    const [loading, setLoading] = useState(true);
    

    const modules = {
        toolbar: [
            [{ 'header': [1, 2,3, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    // Getting Course
    useEffect(() => {
        
        if (authTokens && CourseID) {
            if (isEditStatus ){
                getCourse();
                getCourseSection();
            };
        }else{
            logoutUser();
        }
    }, [authTokens, CourseID]);

    const getCourse = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/editview/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course");
            setCourses(res.data);
            setName(res.data.name);
            setDescription(res.data.description);
            setPrice(res.data.price);
            setCourseImageurl(res.data.course_image);
            setLoading(false);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };

    
    // Making Dialog
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOnCourse(true);
        setTitle("");
        setDescriptionsection("");
        setImage(null);
        setAudio(null);
        setDocument(null);
        setVideo(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTitle("");
        setDescriptionsection("");
        setImage(null);
        setAudio(null);
        setDocument(null);
        setVideo(null);
    };


    const changeEdit = () =>{
        console.log("Change Detected")
        if(isEditStatus){
            setIsEditStatus(false);
        }else{
            // Updating Course Content
            handleUpdateCourse();
        }
    };
    

    // Handel Poster Change
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setCourseImage(file);
    
          // Create a preview URL for the image
          const imageUrl = URL.createObjectURL(file);
          setCourseImageurl(imageUrl);
        }
      };


    const handleUpdateCourse = async () => {
    
        // Create form data to include fields and file
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('price', price);
    
        // If there is an image, append it
        if (courseImage) {
          formData.append('course_image', courseImage);
        }
    
        try {
          // Axios request to update the course
          const response = await axios.put(
            `${myConfig.CRU_URL}/courses/editview/${CourseID}/`, 
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${authTokens.access}`, 
              },
            }
          );
    
          console.log('Course updated:', response.data);
          setCourseImageurl(response.data.course_image);
          setIsEditStatus(true);
          
          alert('Course updated successfully!');
        } catch (error) {
          console.error('Error updating course:', error.response?.data || error.message);
          alert('Failed to update the course. Please try again.');
        }
    };

    // Add Section
    const handleCourseSectionSubmit = async (e) => {
        e.preventDefault();
        
        // Create a FormData object to handle files
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', descriptionsection);
        if (image) formData.append('image', image);
        if (document) formData.append('document', document);
        if (audio) formData.append('audio', audio);
        if (video) formData.append('video', video);
        formData.append('main_course', CourseID); // mainCourseId is passed as a prop

        try {
            const response = await axios.post(`${myConfig.CRU_URL}/courses/course-insides/${CourseID}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authTokens.access}`
                },
            });
            console.log('Response:', response.data);
            // Close the dialog or show success notification
            getCourseSection();
            handleClose();
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    // Edit Section
    const sectionchangeEdit = () =>{
        console.log("Section Change Detected")
        if(isEditStatus){
            setIsEditStatus(false);
        }else{
            // Updating Course Content
            handleCourseSectionChangeSubmit();
        }
    };
    const handleCourseSectionChangeSubmit = async () => {
        
        // Create a FormData object to handle files
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', descriptionsection);
        if (image) formData.append('image', image);
        if (document) formData.append('document', document);
        if (audio) formData.append('audio', audio);
        if (video) formData.append('video', video);
        formData.append('main_course', CourseID); // mainCourseId is passed as a prop

        try {
            const response = await axios.put(`${myConfig.CRU_URL}/courses/course-insides/${CourseID}/${sectionId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authTokens.access}`
                },
            });
            console.log('Response:', response.data);
            // Close the dialog or show success notification
            getCourseSection();
            setIsEditStatus(true);

        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    // Get All Section
    const getCourseSection = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course Section");
            setsection(res.data);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };

    // Get All Section
    const getCourseSectionItem = async (sectionID) => {
        const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/${sectionID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course Section");

            setTitle(res.data.title);
            setDescriptionsection(res.data.description);
            setOnCourse(false);
            setIsEditStatus(true);
            setSectionId(sectionID);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };
    

    const handleFileChange = (e, setFile) => {
        setFile(e.target.files[0]);
    };

    return(
        <>
            {loading ? (
                // Skeleton layout while loading
                <div className="p-8">
                    <div className="md:flex w-full gap-3 md:h-screen">
                        <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl p-5 scroll-m-2">
                            <Skeleton variant="rectangular" width="100%" height={400} />
                            <Skeleton variant="text" width="60%" height={40} className="my-5" />
                            <Skeleton variant="text" width="40%" height={30} />
                            <Skeleton variant="rectangular" width="100%" height={200} className="my-5" />
                        </div>
                        <div className="w-full rounded-md p-5 border-2 shadow-2xl">
                            <Skeleton variant="text" width="40%" height={30} />
                            <Skeleton variant="rectangular" width="100%" height={300} className="my-5" />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {courses && (
                        <div className="p-8">
                            <div className="md:flex w-full gap-3 md:h-screen">
                                <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl p-5 scroll-m-2">
                                    <div className="md:h-96 border-2 rounded-xl p-5 bg-slate-100 relative">
                                        <img
                                            className="h-full w-fit mx-auto rounded shadow-2xl shadow-gray-400"
                                            src={courseImageurl}
                                            alt="Course"
                                        />
                                    </div>
                                    {onCourse ? (
                                        <div className="py-5">
                                            <div className="flex justify-between items-center">
                                                <div className="font-black text-2xl w-9/12">
                                                    {isEditStatus ? (
                                                        <>{name}</>
                                                    ) : (
                                                        <TextField
                                                            label="Course Name"
                                                            className="w-full"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    )}
                                                </div>
                                                <Button size="medium" variant="outlined" onClick={changeEdit}>
                                                    <div className="flex items-center">
                                                        {isEditStatus ? (
                                                            <>
                                                                <EditOutlinedIcon className="h-4 w-4 " /> Edit Section
                                                            </>
                                                        ) : (
                                                            <>
                                                                <SaveAltIcon className="h-4 w-4 " /> Save
                                                            </>
                                                        )}
                                                    </div>
                                                </Button>
                                            </div>
                                            <div className="font-semibold text-xs">
                                                By {courses.expert.first_name} {courses.expert.last_name}
                                            </div>
                                            <br />
                                            {isEditStatus ? (
                                                <div>
                                                    <div className="font-bold text-xl justify-center">
                                                        <CurrencyRupeeIcon /> {price}
                                                    </div>
                                                    <div
                                                        className="mt-10 font-size:inherit; font-weight:inherit"
                                                        dangerouslySetInnerHTML={{ __html: description }}
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <FormControl>
                                                        <InputLabel htmlFor="outlined-adornment-amount">
                                                            Amount
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-amount"
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <CurrencyRupeeIcon />
                                                                </InputAdornment>
                                                            }
                                                            label="Amount"
                                                            value={price}
                                                            onChange={(e) => setPrice(e.target.value)}
                                                            type="number"
                                                        />
                                                    </FormControl>
                                                    <ReactQuill
                                                        className="rounded-lg mt-5 h-full bg-white"
                                                        formats={formats}
                                                        modules={modules}
                                                        theme="snow"
                                                        value={description}
                                                        onChange={setDescription}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        // Section editing logic
                                        <div className="py-5">
                                            <div className="flex justify-between items-center">
                                                <div className="font-black text-2xl w-8/12">
                                                    {isEditStatus ? (
                                                        <>{title}</>
                                                    ) : (
                                                        <TextField
                                                            label="Course Name"
                                                            className="w-full"
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                        />
                                                    )}
                                                </div>
                                                <Button size="medium" variant="outlined" onClick={sectionchangeEdit}>
                                                    <div className="flex items-center">
                                                        {isEditStatus ? (
                                                            <>
                                                                <EditOutlinedIcon className="h-4 w-4 " /> Edit Section
                                                            </>
                                                        ) : (
                                                            <>
                                                                <SaveAltIcon className="h-4 w-4 " /> Save
                                                            </>
                                                        )}
                                                    </div>
                                                </Button>
                                                <Button
                                                    size="medium"
                                                    color="error"
                                                    variant="outlined"
                                                    disabled
                                                >
                                                    <div className="flex items-center">
                                                        <DeleteIcon className="h-4 w-4 " /> Delete
                                                    </div>
                                                </Button>
                                            </div>
                                            <br />
                                            <div className="font-semibold text-xs">
                                                By {courses.expert.first_name} {courses.expert.last_name}
                                            </div>
                                            <div className="font-bold text-xl justify-center">
                                                <CurrencyRupeeIcon /> {price}
                                            </div>
                                            {isEditStatus ? (
                                                <div
                                                    className="mt-10 font-size:inherit; font-weight:inherit"
                                                    dangerouslySetInnerHTML={{
                                                        __html: descriptionsection,
                                                    }}
                                                />
                                            ) : (
                                                <ReactQuill
                                                    className="rounded-lg mt-5 h-full bg-white"
                                                    formats={formats}
                                                    modules={modules}
                                                    theme="snow"
                                                    value={descriptionsection}
                                                    onChange={setDescriptionsection}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="overflow-hidden w-full rounded-md  border-2 shadow-2xl">
                                    <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-xl transition duration-200">
                                        <div className="font-bold text-xl uppercase">Course Sections</div>
                                        <Button size="medium" onClick={handleClickOpen}><div className="flex items-center"><AddIcon className="h-4 w-4 "/>Add new Section</div></Button>
                                        <Dialog fullWidth  open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                                            <DialogTitle id="responsive-dialog-title">
                                                {"Add Course Section"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Fill in the details to add a new course section.
                                                </DialogContentText>
                                                
                                                {/* Title Input */}
                                                <TextField
                                                    autoFocus
                                                    margin="dense"
                                                    label="Section Title"
                                                    type="text"
                                                    fullWidth
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    required
                                                />
                                                
                                                {/* Description Input */}
                                                {/* <TextField
                                                    margin="dense"
                                                    label="Description"
                                                    type="text"
                                                    multiline
                                                    rows={4}
                                                    fullWidth
                                                    value={descriptionsection}
                                                    onChange={(e) => setDescriptionsection(e.target.value)}
                                                    required
                                                /> */}
                                                <ReactQuill className='rounded-lg my-5 h-full bg-white' formats={formats} modules={modules} theme="snow" value={descriptionsection} onChange={setDescriptionsection}>
                                                </ReactQuill>
                                                <div className="grid grid-cols-2 gap-2">
                                                    {/* Image Input */}
                                                    <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        id="upload-image"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, setImage)}
                                                        disabled
                                                    />
                                                    <label htmlFor="upload-image">
                                                        <Button variant="contained" className="w-11/12" color="primary" component="span" fullWidth disabled>
                                                            Upload Image
                                                        </Button>
                                                    </label>

                                                    {/* Document Input */}
                                                    <input
                                                        accept="application/pdf"
                                                        style={{ display: 'none' }}
                                                        id="upload-document"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, setDocument)}
                                                        disabled
                                                    />
                                                    <label htmlFor="upload-document">
                                                        <Button variant="contained" className="w-11/12" color="primary" component="span" fullWidth disabled>
                                                            Upload Document
                                                        </Button>
                                                    </label>

                                                    {/* Audio Input */}
                                                    <input
                                                        accept="audio/*"
                                                        style={{ display: 'none' }}
                                                        id="upload-audio"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, setAudio)}
                                                        disabled
                                                    />
                                                    <label htmlFor="upload-audio">
                                                        <Button variant="contained" className="w-11/12" color="primary" component="span" fullWidth disabled>
                                                            Upload Audio
                                                        </Button>
                                                    </label>

                                                    {/* Video Input */}
                                                    <input
                                                        accept="video/*"
                                                        style={{ display: 'none' }}
                                                        id="upload-video"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, setVideo)}
                                                        disabled
                                                    />
                                                    <label htmlFor="upload-video">
                                                        <Button variant="contained" className="w-11/12" color="primary" component="span" fullWidth disabled>
                                                            Upload Video
                                                        </Button>
                                                    </label>
                                                </div>
                                            </DialogContent>
                                            
                                            <DialogActions>
                                                <Button onClick={handleClose} color="error">
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleCourseSectionSubmit} color="primary" variant="outlined">
                                                    Create Section
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                    <hr />
                                    <div className="overflow-auto p-5">
                                        <List>
                                        {section.map((item) => (
                                            // <div className="flex">
                                            //     <Button className="w-10/12 py-2 px-2.5 font-semibold hover:bg-blue-100 rounded-sm text-left"  color="primary">
                                            //         {item.title}
                                            //     </Button>
                                            //     <Button className=" text-left py-2 px-2.5 font-semibold hover:bg-red-100 rounded-sm"  color="error">
                                            //         Delete
                                            //     </Button>

                                            // </div>
                                                <div className="p-3 transition duration-200 hover:scale-105 hover:border hover:border-blue-600 hover:text-blue-600 rounded-sm cursor-pointer" onClick={() => getCourseSectionItem(item.id.toString())}>
                                                    <div className="flex">
                                                        <div className="flex-1">{item.title} </div>
                                                    </div>
            
                                                </div>

                                        ))
                                        }
                                        </List>
                                        

                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                    )}
                </>
            )}
        </>
    )
}