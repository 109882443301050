import React, { useContext, useEffect, useState } from "react";
import rohan from "../assests/experts/rohan.png"
import second from "../assests/images/second.png"

export function Blog2() {

    return (
        <div className="flex justify-center items-center bg-black text-gray-300">
            <div className="w-3/4 p-10 my-10 rounded-lg border-gray-600 border">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <div className="w-12 h-12 rounded-full  flex justify-center items-center overflow-hidden">
                            <img className="w-12 h-12" src={rohan} alt="User profile" />
                        </div>
                        <div>
                            <h1 className=" text-3xl font-bold">Web3: The Future of the Internet - Decentralization</h1>
                            <p className="text-sm">
                                Author: <span className="font-bold">Rohan Ramteke</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="m-4">
                    <img src={second} alt="blog" className="w-full h-72 object-fit rounded-md" />
                </div>
                <div className="items-center justify-center">
                    <br/>
                <h3 className=" font-bold">Understanding Web2: The Internet We Know</h3>
                <p>
                    Before we dive into Web3, let's take a quick look at Web2, which is the internet we are all familiar with. Web2 
                    is characterized by centralized platforms and services that dominate our online experience. Social media giants, 
                    search engines, and e-commerce platforms are some examples of Web2 services.
                </p>
                <p>
                    In Web2, these platforms act as intermediaries, controlling the flow of data and information. They store our personal 
                    data, track our online activities, and make decisions on what content we see, often based on targeted advertising. 
                    While Web2 has undoubtedly brought convenience and connectivity, it has also raised concerns about privacy, data 
                    ownership, and censorship.
                </p>
                <br />
                <h3 className="font-bold">Introducing Web3: A Paradigm Shift in Decentralization</h3>
                <p>
                    Web3 represents a fundamental shift in the way we interact with the internet. At its core, Web3 is built on the 
                    principles of decentralization, giving more power and control back to users.
                </p>

                <br />
                <h4 className="font-bold">1. Decentralization:</h4>
                <p>
                    In Web3, decentralized technologies like blockchain play a crucial role. Unlike Web2's centralized servers, Web3 relies 
                    on a distributed network of computers (nodes), where data is not stored in a single location but spread across the entire 
                    network. This architecture eliminates the need for intermediaries, making it more challenging for a single entity to 
                    control and manipulate data.
                </p>

                <h4 className="font-bold">2. User Ownership of Data:</h4>
                <p>
                    With Web3, users have greater ownership and control over their data. Instead of surrendering personal information to 
                    centralized platforms, Web3 allows users to store their data securely on decentralized networks. Users can decide what 
                    data they share and with whom, enhancing privacy and reducing the risk of data breaches.
                </p>

                <h4 className="font-bold">3. Interoperability:</h4>
                <p>
                    Web3 aims to create a seamless and interconnected online ecosystem. Different blockchain-based applications can 
                    communicate and interact with each other, fostering a more cohesive and open internet.
                </p>

                <h4 className="font-bold">4. Digital Assets and Cryptocurrencies:</h4>
                <p>
                    Web3 embraces the concept of digital assets and cryptocurrencies. These assets can represent ownership of real-world 
                    assets, access to services, or even unique digital creations. Cryptocurrencies, like Bitcoin and Ethereum, enable 
                    peer-to-peer transactions without the need for traditional financial institutions.
                </p>

                <h4 className="font-bold">5. Smart Contracts:</h4>
                <p>
                    Smart contracts are self-executing agreements with the terms directly written into code. They automatically execute 
                    when certain conditions are met, removing the need for intermediaries. This opens up new possibilities for 
                    decentralized applications (DApps) that operate autonomously and transparently.
                </p>

                <br />
                <h3 className="font-bold">Web2 vs. Web3: The Key Differences</h3>
                <h4 className="font-bold">1. Ownership:</h4>
                <p>
                    In Web2, data and user information are usually owned and controlled by centralized platforms. In Web3, users have 
                    more ownership and control over their data, deciding how it is used and shared.
                </p>

                <h4 className="font-bold">2. Intermediaries:</h4>
                <p>
                    Web2 heavily relies on intermediaries like social media platforms, search engines, and payment processors. Web3 
                    reduces the need for intermediaries by utilizing decentralized networks and protocols.
                </p>

                <h4 className="font-bold">3. Privacy:</h4>
                <p>
                    Web2 often involves data collection and tracking for targeted advertising. Web3's decentralized approach enhances user 
                    privacy, as personal data is not concentrated in one place.
                </p>

                <h4 className="font-bold">4. Economic Paradigm:</h4>
                <p>
                    Web2 typically relies on traditional financial systems. Web3 introduces cryptocurrencies and blockchain-based 
                    economies, enabling new models of transactions and value exchange.
                </p>
                <br />
                <h3 className="font-bold">Conclusion</h3>
                <p>
                    Web3 represents a groundbreaking shift in the way we experience the internet. It promises a more democratic, transparent, 
                    and user-centric online ecosystem. By embracing decentralization, empowering users with data ownership, and integrating 
                    blockchain technology, Web3 aims to redefine how we interact with digital services and reshape the future of the internet. 
                    As Web3 continues to evolve and gain traction, it opens up exciting possibilities for innovation, disruption, and a more 
                    inclusive internet for everyone.
                </p>
                </div>
            </div>
        </div>
    );
}
