
import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Navigate, useNavigate }from "react-router-dom";
import myConfig from "../configs/config"

// This is the context that will be used to wrap the app for getting user data globally
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

  let [authTokens, setAuthToken] = useState( () => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
  let [user, setUser] = useState( () => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null);
  let [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  // console.log("user",user);
  let loginUser = async (e = null) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      let response = await fetch(`${myConfig.CRU_URL}/account/login/`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json", // this is the type of data we are sending
          },
          body: JSON.stringify({
              'email': e.target.email.value, 
              'password':e.target.password.value
          }),
      });
      let data = await response.json();
      if (response.status === 200){
        setAuthToken(data);
        setUser(jwtDecode(data.access)); // decode the token to get the user data through jwtDecode package
        localStorage.setItem('authTokens', JSON.stringify(data));
        navigate('/profile');
        setTimeout(() => {
          window.location.reload();
        }, 100); // Adjust the delay as needed (100ms here)
        return true;
      }else{
        alert("Invalid username or password");
      }  
    };
  let logoutUser = () => {
    setAuthToken(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    navigate('/login');
    window.location.reload();
  };
  let logoutUserPath = (path) => {
    setAuthToken(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    navigate(`${path}`);
  };
  
  let updateTokens = async () => {
    // console.log("Updating tokens");
    let response = await fetch(`${myConfig.CRU_URL}/account/login/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // this is the type of data we are sending
        "Authorization": `Bearer ${authTokens.access}`
      },
      body: JSON.stringify({
        'refresh': authTokens.refresh
      }),
    });
    let data = await response.json();

    if (response.status === 200){
      setAuthToken(data);
      setUser(jwtDecode(data.access)); // decode the token to get the user data through jwtDecode package
      localStorage.setItem('authTokens', JSON.stringify(data));
    }else{
      logoutUser();
    }
  };

  // This is the data that will be available to the app
  let contextData = {
    user:user,
    authTokens:authTokens,
    loginUser:loginUser,
    logoutUser:logoutUser,
    logoutUserPath:logoutUserPath
  }

  useEffect(() => {
    
    let fourMinutes = 4 * 60 * 1000;
    let interval = setInterval(() => {
      if (authTokens){
        updateTokens(authTokens);
      }
    }, fourMinutes);
    return () => clearInterval(interval)

  }, [authTokens, loading])

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};
