import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import myConfig  from "../configs/config"
import AuthContext from "../context/AuthContext";
import {CartContext}  from "../context/CartContext";
import AddIcon from '@mui/icons-material/Add';
import { Bounce, toast } from "react-toastify";

import Button from '@mui/material/Button';
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RemoveShoppingCartRoundedIcon from '@mui/icons-material/RemoveShoppingCartRounded';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { createTheme, Skeleton, ThemeProvider } from '@mui/material';




export function CartCourse() {

    const { removeFromCart, CourseCount, transferCartToLearning } = useContext(CartContext);
    const [courses, setCourses] = useState([]);
    const [price, setPrice] = useState(0);
    const [priceAfter, setPriceAfter] = useState(0);
    const [loading, setLoading] = useState(false);
    // Dialog
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
        

    const {authTokens, logoutUser, user} = useContext(AuthContext)
    // Get All Blogs
    useEffect(() => {
		getCart();
	}, []);
    let getCart = async () => {
        const cartUrl = `${myConfig.CRU_URL}/courses/getcart/`;
        axios({
            baseURL: cartUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
        .then((res) => {
            // console.log(res)
            // console.log("getting Cart");
            setCourses(res.data);

            let totalPrice = 0;
            res.data.forEach((element) => {
                if(element.new_discounted_price !== 0){
                    totalPrice +=element.new_discounted_price;
                }else{
                    totalPrice += element.course.price; 
                }
            });
            setPrice(totalPrice);
            // console.log(price);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            // console.log(error);
        })
    }

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const [couponCodes, setCouponCodes] = useState({}); // To store input values for each course

    // Handle input change
    const handleInputChange = (courseId, value) => {
        setCouponCodes((prev) => ({ ...prev, [courseId]: value }));
    };

    // Handle Apply button click
    const onApplyCoupon = async (courseId) => {
        const couponCode = couponCodes[courseId];
        if (!couponCode) {
            alert("Please enter a coupon code");
            return;
        }
        try {
            // Make an API call to verify coupon
            setLoading(true);
            const response = await fetch(`${myConfig.CRU_URL}/courses/verify-coupon/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authTokens.access}` },
                body: JSON.stringify({ course_id: courseId, coupon_code: couponCode })
            });
            const data = await response.json();

            if (data.valid) {
                toast.success('Coupon applied successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                    // Update the discounts state for this course with the discount amount
                    // console.log(data);
                    setCourses(data.cart_data);

                    let totalPrice = 0;
                    data.cart_data.forEach((element) => {
                        if(element.new_discounted_price !== 0){
                            totalPrice +=element.new_discounted_price;
                        }else{
                            totalPrice += element.course.price; 
                        }
                    });
                    setPrice(totalPrice);

            } else {
                toast.error('Invalid coupon code.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
            }
        } catch (error) {
            console.error("Error verifying coupon:", error);
            alert("There was an error verifying the coupon.");
        }finally{
            setLoading(false);
        }
    };
    
    return(
        // <ThemeProvider theme={darkTheme}>
        <div className="w-full relative bg-black py-10 md:my-auto ">
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <div className=" md:px-28 relative mx-auto  p-8 rounded-lg ">
                <div className="flex justify-between items-center">
                    <div className="text-3xl text-gray-200 font-bold w-fit hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-400">Cart</div>
                    <div className="flex gap-2">
                        <Link to="/courses" className=" items-center ">
                            <Button variant="outlined"><AddIcon className="h-6 w-auto"/>Add Courses</Button>
                        </Link>
                        {price ?
                            <Button variant="contained" onClick={handleClickOpen}><CurrencyRupeeIcon className="h-6 w-auto" fontSize="string" />{price}</Button>
                            :
                            <></>
                        }
                        
                        <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                        >
                            <DialogTitle className="bg-black" id="alert-dialog-title">
                                <div className="uppercase tracking-wide text-xl w-fit font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500">Proceed To Pay</div>
                            </DialogTitle>
                            <hr className="border-gray-600" />
                            <div className="overflow-y-scroll bg-black p-5">
                            {courses.map((item) => {
                                return (
                                    <div key={`dialog${item.id}`} className="flex my-5 p-3 border border-gray-600 bg-gray-950 rounded-md shadow-md hover:shadow-lg" id={`course_${item.id}`}>
                                        <div className="flex-1">
                                            <img src={`${item.course.course_image}`} title="Course Image" className="rounded-md" alt="Course" />
                                        </div>
                                        <div className="flex-1 px-2">
                                            <div className="font-bold text-gray-200">{item.course.name}</div>
                                            <div className="text-sm font-thin text-gray-500">
                                                By: {item.course.expert.first_name} {item.course.expert.last_name}
                                            </div>
                                            <div className="font-semibold mt-3 text-gray-200">
                                                {item.new_discounted_price !==0 ?
                                                <>Price: ₹<span style={{textDecoration:"line-through"}}>{item.course.price}</span>{item.new_discounted_price} </>:
                                                <>Price: ₹{item.course.price}</>
                                                }
                                                {item.coupon_code !==null ?
                                                <div className="w-fit bg-gray-900 border rounded-md border-gray-600 text-gray-300 px-2 text-xs">
                                                    {item.coupon_code.code}
                                                </div>
                                                :null
                                                }
                                            </div>
                                            <div className="flex mt-2 w-full text-gray-300 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                                                <input
                                                    type="text"
                                                    value={couponCodes[item.course.id] || ''}
                                                    onChange={(e) => handleInputChange(item.course.id, e.target.value)}
                                                    placeholder="ABCXXXXX"
                                                    className={`block text-gray-300 pl-3 flex-1 border-0 bg-transparent py-1.5 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${loading&& "cursor-wait"}`}
                                                />
                                                <button onClick={() => onApplyCoupon(item.course.id)} className={`text-sm border rounded-md uppercase p-1 border-gray-600 ${loading && "cursor-not-allowed"}`} disabled={loading}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            </div>
                            <hr className="border-gray-600"  />
                            <DialogActions className="bg-black">
                            <Button onClick={handleClose}  color="error">Cancel</Button>
                            <Button onClick={transferCartToLearning} variant="contained" >
                                Pay ₹{price}
                            </Button>
                            </DialogActions>
                        </Dialog>
                        {/* </Link> */}
                    </div>
                </div>
                

                {courses.length === 0?
                <div className="h-screen relative justify-center items-center">
                    <div className=" ">No Course is Added</div>
                </div>:
                <div className="my-8 grid md:grid-cols-4">
                    {courses.map((item) =>(
                    <div id ={"course_"+item.id} className="relative border border-gray-600">
                        <img
                            className="relative object-fill"
                            src={`${item.course.course_image}`}
                            title="Course Image"
                        />
                        <div className="relative h-44 p-4">
                            <div className="text-gray-200" >
                            {item.course.name}
                            </div>
                            <div className="text-gray-400 text-xs" >
                            {item.course.expert.first_name} {item.course.expert.last_name}
                            </div>
                            
                            <div className="font-semibold mt-3 text-gray-200">
                                <CurrencyRupeeIcon className=""/>
                                {item.new_discounted_price!==0?
                                <>Price: ₹<span style={{textDecoration:"line-through"}}>{item.course.price}</span>{item.new_discounted_price} </>:
                                <>Price: ₹{item.course.price}</>
                                }
                            </div>
                            {item.coupon_code !==null ?
                            <div className="w-fit bg-gray-900 border rounded-md border-gray-600 text-gray-300 px-2 text-xs">
                                {item.coupon_code.code}
                            </div>
                            :null
                            }
                            
                        </div>
                        <div className="w-full absolute bottom-0 p-2 text-gray-200 grid grid-cols-2 gap-x-2 items-center justify-center" >
                            <button className={`uppercase text-sm flex p-2 items-center justify-center border border-gray-600 ${loading&& "cursor-not-allowed"}`} onClick={() => removeFromCart(`${item.course.id}`)} disabled={loading}><RemoveShoppingCartRoundedIcon fontSize="15"/> <span>Remove Item</span></button>
                            <Link className="uppercase text-sm flex p-2 items-center justify-center border border-gray-600" to={`/course/${item.course.id}`}>
                                    <ArrowTopRightOnSquareIcon className="w-4 "/><span className="">Know More</span>
                            </Link>
                        </div>
                    </div>
                    )
                    )}
                </div>
                }
            </div>
        </div>
        // {/* </ThemeProvider> */}
    )
}