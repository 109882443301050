import React, {useContext, useState} from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import myConfig from '../configs/config';
import axios from 'axios';
import { Description } from '@headlessui/react';
import { jwtDecode } from 'jwt-decode';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme, Skeleton, ThemeProvider } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 6,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function CreateBlog() {
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');
    const navigate = useNavigate();

    const [preview, setPreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file)); // Set the preview URL
        }
    };

    const {authTokens, logoutUser} = useContext(AuthContext);

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];
    
    // Submit blog
    const handleSubmit = () => {
        const blogUrl = `${myConfig.CRU_URL}/account/blog/`;
        console.log(title, value, selectedFile);
        if ( title === '' || value === '' || selectedFile === null) {
            console.log('Please fill all fields');
            console.log(title, value, selectedFile);
            alert('Please fill all fields');
            return;
        }
        let form_data = new FormData();
        form_data.append('title', title);
        form_data.append('description', value);
        form_data.append('image', selectedFile);
        form_data.append('user', String(authTokens.access));
        console.log(form_data);
        axios({
            baseURL: blogUrl,
            method: 'POST',
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${authTokens.access}`
            },
            data: form_data
        })
        .then((res) => {
            console.log(res.data);
            if(res.status === 200){
                console.log("Blog Finally Done")
                navigate('/blogs');
            }else if(res.statusText === 'Unauthorized'){
                logoutUser();
            }

        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
        })

    }

    return (
        <div className='relative h-full bg-black '>
            <div class="absolute  duration-1000 animate-pulse bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_2px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_250px_at_50%_200px,#fbfbfb36,#000)]"></div>
            <div className="flex justify-center items-center relative  ">
                <div className="md:w-3/4 md:p-10 md:my-10 rounded-md border bg-black/15 border-gray-600 shadow-md  ">
                    <h1 className="text-2xl font-bold text-gray-200">Create Blog</h1>
                    <ThemeProvider theme={darkTheme}>
                    {preview && (
                        <div className='flex items-center justify-center' >
                            <img src={preview} alt="Selected preview" className='w-auto h-60 object-fill' />
                        </div>
                    )}
                    <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<AddPhotoAlternateIcon />} style={{marginTop:'5px'}}>
                        Select Bolg Poster
                        <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                    </Button>

                    <Box component="div"  className="mt-5"  noValidate autoComplete="off">
                        <TextField type="text" placeholder="Course Name" label="Enter Course Name" className="" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
                    </Box>

                    <label htmlFor="email" className="block text-sm font-semibold mt-6 leading-6 text-gray-900 ">
                        Write Blog 
                    </label>
                    <ReactQuill className='rounded-lg h-full bg-white' formats={formats} modules={modules} theme="snow" value={value} onChange={setValue}>
                    </ReactQuill>
                    </ThemeProvider>
                    <div className="relative mt-8">
                        <button className="text-gray-200 uppercase border-gray-600 border px-3 py-2 rounded-md font-semibold hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-600"  onClick={handleSubmit} >Create</button>
                    </div>
                </div>
            </div>
        </div>
    )
}