import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import myConfig from "../configs/config";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import 'react-quill/dist/quill.snow.css';
import AuthContext from "../context/AuthContext";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';


export function OffViewCourse() {
    const navigate = useNavigate();

    const [courses, setCourses] = useState(null);
    const [onCourse, setOnCourse] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [courseImageurl, setCourseImageurl] = useState('')

    const { CourseID } = useParams();
    const { authTokens, logoutUser, user } = useContext(AuthContext);
    // Section
    const [section, setSection] = useState([]);

    // Getting Course
    useEffect(() => {
        if (authTokens && CourseID) {
            navigate(`/course/${CourseID}`);
        } else {
            getCourse();
        }
    }, []);

    const getCourse = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/getcourse/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course");
            setCourses(res.data.course_data);
            setName(res.data.course_data.name);
            setDescription(res.data.course_data.description);
            setPrice(res.data.course_data.price);
            setCourseImageurl(res.data.course_data.course_image);
            setSection(res.data.course_list);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className=" bg-black">
            {courses ?
                <div className="p-8">
                    <div className="md:flex w-full gap-3 md:h-screen">
                        <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl md:px-5 px-0 scroll-m-2">
                            <div className="md:h-96 border border-gray-600 rounded-xl p-5 bg-gray-600 relative">
                                <img className="h-full w-fit mx-auto shadow-md shadow-gray-900" src={courseImageurl} alt="Course" />
                            </div>
                            <div className="py-5 ">
                                <div className="flex justify-between items-center">
                                    <div className="font-black text-2xl text-gray-200 w-9/12">
                                        {name}
                                    </div>
                                    <Link className="py-2 px-2 text-center text-sm md:text-base border uppercase rounded-sm border-gray-600 text-gray-200   transition duration-200 hover:scale-105 font-semibold" to={"/login"} >Start Now</Link>
                                </div>
                                <div className="font-semibold text-xs text-gray-400">
                                    By {courses.expert.first_name} {courses.expert.last_name}
                                </div>
                                <br />
                                <div>
                                    <div className="font-bold text-xl text-gray-200">
                                        <CurrencyRupeeIcon className="w-4 text-gray-200" />{price}
                                    </div>
                                    <div className="mt-10 text-gray-300" dangerouslySetInnerHTML={{ __html: description }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-hidden w-full rounded-md border border-gray-600 shadow-2xl">
                            <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-mg shadow-gray-500 ">
                                <div className="font-bold text-xl uppercase  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 hover:text-transparent transition-colors duration-500 text-gray-200">Course Topics</div>
                            </div>
                            <div className="p-5">
                                <List>
                                    {section.map((item) => (
                                        <div key={item.id} className="p-3 transition duration-200 hover:scale-105 hover:border hover:border-pink-600 hover:text-red-600 rounded-sm cursor-not-allowed">
                                            <div className="flex">
                                                <div className="flex-1 text-gray-300">{item.title}</div>
                                                <LockClosedIcon className="w-4 text-gray-300" />
                                            </div>
                                        </div>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </div>
                </div>
                :
                // Skeleton while loading
                <div className="p-8">
                    <div className="md:flex w-full gap-3 md:h-screen">
                        <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl px-5 scroll-m-2">
                            <div className="md:h-96 border-2 rounded-xl p-5 bg-gray-800 relative">
                                <Skeleton height={300} sx={{background:"gray"}}/>
                            </div>
                            <div className="py-5">
                                <div className="flex justify-between items-center">
                                    <Skeleton width="60%" height={30} sx={{background:"gray"}}/>
                                    <Skeleton width={100} height={40} sx={{background:"gray"}}/>
                                </div>
                                <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                                <br />
                                <Skeleton height={20} count={3} sx={{background:"gray"}}/>
                            </div>
                        </div>
                        <div className="overflow-hidden w-full rounded-md border-2 shadow-2xl">
                            <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-xl transition duration-200">
                                <Skeleton width={200} height={30}sx={{background:"gray"}} />
                            </div>
                            <div className="p-5">
                                <Skeleton height={20} count={5} sx={{background:"gray"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
