import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserIcon } from "@heroicons/react/20/solid";
import { AcademicCapIcon, PlusIcon } from "@heroicons/react/24/outline";
import { jwtDecode } from "jwt-decode";
import myConfig from "../configs/config";
import AuthContext from "../context/AuthContext";
import blogBackground from "../assests/images/blogbackground.png";
import { Button, Skeleton } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Meteors } from "../components/animation/meteor";
export function Blogs() {
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { authTokens, logoutUser, user } = useContext(AuthContext);

    // Fetch all blogs
    useEffect(() => {
        getBlog();
    }, []);

    const getBlog = async () => {
        const blogUrl = `${myConfig.CRU_URL}/account/blog/`;
        setIsLoading(true);
        
        axios({
            baseURL: blogUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
        .then((res) => {
            setBlogs(res.data);
            setIsLoading(false);
        })
        .catch((error) => {
            if (error.response?.status === 401) {
                logoutUser();
            }
            console.log(error);
            setIsLoading(false);
        });
    };

    return (
        <div className="md:flex gap-5 px-5 py-10 bg-black">
      {/* Blog Section */}
      <div className="md:basis-8/12 rounded-lg backdrop-blur-xl p-8 overflow-y-scroll h-screen ">
          
        {user.is_expert ? (
          <div className="flex justify-between items-center mb-5">
            <h1 className="text-2xl font-bold text-gray-200">Blockchain Pathshala Blogs</h1>
            <Link to="/createblog" >
              <Button variant="outlined" className="hover:bg-blue-600 hover:text-white transition-transform duration-300 transform hover:scale-105">
                <PlusIcon className="h-6 w-auto mr-1" />
                <span>Create Blog</span>
              </Button>
            </Link>
          </div>
        ) : (
          <h1 className="text-2xl font-bold text-gray-800">Blogs</h1>
        )}

        <div className="my-8 space-y-8">
          {isLoading ? (
            [1, 2, 3].map((_, index) => (
              <div key={index} className="my-5 p-5 rounded-md bg-white/90 shadow-lg animate-pulse">
                <Skeleton variant="rectangular" width="100%" height={200} />
                <div className="flex gap-x-5 mt-5">
                  <Skeleton variant="circular" width={50} height={50} />
                  <div className="w-full">
                    <Skeleton variant="text" width="60%" height={30} />
                    <Skeleton variant="text" width="40%" height={20} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            blogs.map((item) => (
              <div className="p-5 bg-white/10 rounded-lg border border-gray-700 hover:shadow-xl transition ease-in-out duration-500 transform hover:scale-105" key={item.id}>
                <img className="object-fit w-full h-64 rounded-md" src={item.image} alt="Blog" loading="lazy" />
                <div className="mt-5 flex gap-x-5 items-center">
                  <div className="w-12 h-12 rounded-full bg-gray-800 flex items-center justify-center overflow-hidden">
                    {!item.user.user_profile ? (
                      <UserIcon className="h-6 w-auto text-white" />
                    ) : (
                      <img className="h-12 w-12 rounded-full object-cover" src={item.user.user_profile} alt="User Profile" />
                    )}
                  </div>
                  <div>
                    <Link to={`/blog/${String(item.id)}`} className="font-bold text-xl text-gray-200 transition-colors duration-200">
                      {item.title}
                    </Link>
                    <p className="text-sm text-gray-400">Author: <span className="font-bold">{item.user.first_name} {item.user.last_name}</span></p>
                  </div>
                  <Link to={`/blog/${String(item.id)}`} className="ml-auto flex items-center justify-center group">
                    <button className=" uppercase text-gray-200 group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-blue-400 group-hover:to-pink-400">Read More<ArrowForwardIcon className="ml-1 group-hover:text-pink-400" /></button>
                  </Link>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Top Blogs Section */}
      <div className="md:basis-4/12   ">
        <div className="mt-8 space-y-6 h-auto border border-gray-600 backdrop-blur-xl shadow-lg p-8 rounded-sm overflow-hidden">
        {/* <Meteors /> */}
            <h1 className="text-2xl font-bold text-gray-200">Top Blogs</h1>
          {isLoading ? (
            [1, 2, 3].map((_, index) => (
              <div key={index} className="animate-pulse  ">
                <Skeleton variant="text" width="80%" height={30} sx={{background:"gray"}}/>
                <Skeleton variant="text" width="40%" height={20} sx={{background:"gray"}}/>
                <hr className="mt-1" />
              </div>
            ))
          ) : (
            blogs.map((item) => (
              <div key={item.id} className="border-b p-2 transition ease-in-out duration-100 hover:scale-105  hover:shadow-md rounded-sm group ">
                <Link to={`/blog/${String(item.id)}`} className="font-bold text-md text-gray-200 group-hover:text-blue-500 transition-colors duration-200">
                  {item.title}
                </Link>
                <div className=" text-xs text-gray-300 uppercase">Author: <span className="font-semibold text-sm">{item.user.first_name} {item.user.last_name}</span></div>
              </div>
            ))
          )}
        </div>
      </div>

      <ToastContainer />
    </div>
    );
}
