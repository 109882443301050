import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserIcon } from "@heroicons/react/20/solid";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import myConfig from "../../configs/config";
import axios from "axios";
import { Description } from "@headlessui/react";
import AuthContext from "../../configs/config";
import TextField from '@mui/material/TextField';
import { createTheme, Skeleton, ThemeProvider } from '@mui/material';
import { LastPageTwoTone } from "@mui/icons-material";


export function ExpertProfile() {

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState(''); 
  const [lastName, setLastName] = useState('');
  const [Description, setDescription] = useState('');
//   const [jsonData, setJsonData] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  useEffect(() => {
    getProfile();
  }, []);
  const { expertID } = useParams();

  let getProfile = async () => {
    let response = await fetch(`${myConfig.CRU_URL}/account/getexperts/${expertID}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let data = await response.json();
    console.log(data);
    setProfile(data);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setPreviewUrl(data.user_profile);
    // setJsonData(data.json_data);
    setDescription(data.description);
    setLoading(false);
  };

  
  

  return (
    <div className="w-full relative mx-auto bg-black py-20 px-14">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
        <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
      <div className="grid relative md:grid-cols-2 mx-auto border w-fit border-gray-600 backdrop-blur-sm rounded-lg justify-center">
        <div className=" p-8">
            {loading ? (
                <Skeleton variant="rectangular" width={160} height={160} sx={{background:"gray"}} className="mx-auto" />
            ) : (
                previewUrl ? 
                <img src={previewUrl} className="w-80 h-80 mx-auto z-10 object-cover rounded-md" alt="User Profile"/> : 
                <UserIcon className="h-56 w-56 mx-auto text-gray-400 rounded-full" alt="User Profile"/>
            )}

        </div>
        <div className="p-14  shadow-md text-gray-200 ">
            <div className="uppercase tracking-wide text-sm font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 w-fit">Profile</div>
            <div>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                    <p className="mt-1 text-sm leading-6 ">
                        {loading ? (
                        <Skeleton variant="text" width={300} height={20} sx={{background:"gray"}}/>
                        ) : (
                        <>
                        </>
                        )}
                    </p>

                    <ThemeProvider theme={darkTheme}>
                        <div className="">
                        {loading ? (
                            Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton key={index} variant="rectangular" width="100%" height={56} sx={{background:"gray"}} />
                            ))
                        ) : (<>
                            <div className="mb-5">
                                {firstName} {lastName}
                            </div>

                            <h5 className="mb-1 text-sm  text-transparent uppercase bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 w-fit">About Expert </h5>
                            {/* <hr className="mb-4 border-gray-600" /> */}
                            <div>
                            {Description}
                            </div>
                            </>
                        )}
                        </div>
                    </ThemeProvider>
                    </div>
                </div>

            
            </div>

        </div>
      </div>
    </div>
  )
}
