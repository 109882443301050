import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from "axios";
import myConfig from "../configs/config";
import SendIcon from '@mui/icons-material/Send';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export function OurExperts() {
    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        getExperts();
    }, []);

    const getExperts = async () => {
        const courseUrl = `${myConfig.CRU_URL}/account/allexperts/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting user Section");
            setExperts(res.data);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                // handle 401 error here
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false); // Stop loading
        });
    };

    return (
        <div className='relative overflow-y-hidden bg-black'>
            <div className='flex items-center justify-center py-8'>
                <div className='w-fit text-3xl font-bold bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 text-transparent '>
                    Blockchain Pathshala Experts
                </div>
            </div>
            <div className='grid md:grid-cols-3 py-10 md:px-36 md:gap-20'>
                {loading ? (
                    // Display skeletons while loading
                    Array.from(new Array(4)).map((_, index) => (
                        <div key={index} className='bg-gray-600 rounded-lg shadow-xl hover:shadow-lg'>
                            <Skeleton variant="rectangular" height={200} />
                            <div className='p-3'>
                                <Skeleton height={30} />
                                <Skeleton height={20} width={80} className='mx-auto' />
                            </div>
                            <div className='p-3 flex mx-auto justify-between items-center'>
                                <Skeleton variant="text" width="45%" height={40} />
                                <Skeleton variant="text" width="45%" height={40} />
                            </div>
                        </div>
                    ))
                ) : (
                    experts.map((item) => (
                        <div key={item.id} className='relative border border-gray-700 rounded-md overflow-hidden'>
                            <img src={`${item.user_profile}`} alt={item.name} className=' rounded-t-md w-full h-72 object-cover' />
                            <div className=' p-4 h-40 '>
                                <div className='text-xl font-semibold text-center  text-gray-200'>
                                    {item.first_name} {item.last_name}
                                </div>
                                <div className='text-sm text-center text-gray-500'>BlockChain Expert</div>
                            </div>
                            <p className='text-center'>{item.expertise}</p>
                            <div className=' absolute bottom-0 w-full grid grid-cols-1 gap-4 p-4 '>
                                {/* <button className='flex-1 text-sm flex group text-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105' href={item.linkedin} target='_blank' >
                                    <LinkedInIcon fontSize='small'/> LinkedIn
                                </button> */}
                                <Link className='flex-1 text-sm flex  group text-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300  font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105' to={`/expert-profile/${item.id}`}>
                                 <span className=' w-fit group-hover:bg-clip-text group-hover:text-transparent text-center justify-center group-hover:bg-gradient-to-r flex group-hover:from-blue-500 group-hover:to-pink-500'><ArrowTopRightOnSquareIcon className=" w-4 group-hover:text-blue-500/75"/> Read More</span>
                                </Link>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
