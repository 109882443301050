import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from "axios";
import myConfig from "../configs/config"
import AuthContext from './AuthContext';
import { Bounce, toast } from "react-toastify";
import { Navigate, useNavigate }from "react-router-dom";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartCount, setCartCount] = useState(0);
    const navigate = useNavigate();
    const {authTokens, logoutUser } = useContext(AuthContext);
    useEffect(() => {
        // Initialize cart count from localStorage
        const initialCount = parseInt(localStorage.getItem('cartCount')) || 0;
        setCartCount(initialCount);
    }, []);


    // Adding Item to cart
    const addToCart = async (courseId) => {
        let form_data = new FormData();
        form_data.append('courseId', courseId);
    
        const cartUrl = `${myConfig.CRU_URL}/courses/addcart/`;
    
        // Wrap the axios call in `toast.promise`
        await toast.promise(
            axios({
                baseURL: cartUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authTokens.access}`
                },
                data: form_data
            }),
            {
                pending: "Adding item, please wait...",
                success: {
                    render({ data }) {
                        // Custom success handling here if needed
                        setCartCount(data.data.total_courses);
                        localStorage.setItem('cartCount', data.data.total_courses);
                        return 'Course added to cart successfully!';
                    },
                    icon: "🛒",
                },
                error: {
                    render({ data }) {
                        if (data.response && data.response.status === 400) {
                            return `Error: ${data.response.data.message}`;
                        } else if (data.response && data.response.status === 401) {
                            logoutUser();
                            return "Unauthorized, logging out...";
                        }
                        return "An error occurred. Please try again.";
                    },
                    icon: "⚠️",
                },
            },
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            }
        );
    };
    

    // Removing item form Cart
    const removeFromCart = async (courseId) => {
        const removeCartUrl = `${myConfig.CRU_URL}/courses/removecart/${courseId}/`;
    
        await toast.promise(
            axios.post(
                removeCartUrl,
                {}, // Empty object since no data is required in the body
                {
                    headers: {
                        Authorization: `Bearer ${authTokens.access}`
                    }
                }
            ),
            {
                pending: "Removing item from cart...",
                success: {
                    render({ data }) {
                        // Update cart count on success
                        setCartCount(data.data.total_courses);
                        localStorage.setItem('cartCount', data.data.total_courses);
                        window.location.reload();
                        return "Item removed from cart!";
                    },
                    icon: "🛒",
                },
                error: {
                    render({ data }) {
                        if (data.response) {
                            return `Error: ${data.response.data.message || "An error occurred"}`;
                        }
                        return "Failed to remove item from cart. Please try again.";
                    },
                    icon: "⚠️",
                },
            },
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            }
        );
    };
    

    const transferCartToLearning = async () => {
        const transferUrl = `${myConfig.CRU_URL}/courses/transfer-cart/`;
    
        await toast.promise(
            axios.post(
                transferUrl,
                {}, // No data in body
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                    },
                }
            ),
            {
                pending: "Purchasing Items to My Learning...",
                success: {
                    render({ data }) {
                        // Update cart count and navigate upon success
                        setCartCount(0); // Reset cart count as items are transferred
                        localStorage.setItem('cartCount', 0);
                        window.location.reload();
                        navigate('/my-learning');
                        return data.data.message || "Items successfully Purchased!";
                    },
                    icon: "📚",
                },
                error: {
                    render({ data }) {
                        if (data.response) {
                            return `Error: ${data.response.data.message || "Purchased failed"}`;
                        } else if (data.request) {
                            return "No response from server. Please try again.";
                        }
                        return "An unexpected error occurred. Please try again.";
                    },
                    icon: "⚠️",
                },
            },
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
                transition: Bounce,
            }
        );
    };
    

    const CourseCount = (totalCourseCount) =>{
        setCartCount(totalCourseCount);
    }

    return (
        <CartContext.Provider value={{ cartCount, addToCart, removeFromCart, CourseCount, transferCartToLearning }}>
        {children}
        </CartContext.Provider>
    );
};
