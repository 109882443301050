import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import myConfig from "../configs/config";
import AuthContext from "../context/AuthContext";
import { CartContext } from "../context/CartContext";
import AddIcon from '@mui/icons-material/Add';

// Course Card
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { ArrowTopRightOnSquareIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import Skeleton from '@mui/material/Skeleton';


export function Course() {
    const { addToCart } = useContext(CartContext);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const { authTokens, logoutUser, user } = useContext(AuthContext);

    // Get All Courses
    useEffect(() => {
        getCourses();
    }, []);

    let getCourses = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
        .then((res) => {
            console.log(res.data);
            setCourses(res.data.course_data);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false); // Stop loading
        });
    };

    return (
        <div className="w-full relative bg-black mx-auto py-10 md:my-auto">
            <div className="absolute  duration-1000 animate-pulse bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_2px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_250px_at_50%_200px,#fbfbfb36,#000)]"></div>
            <div className="md:px-28 relative mx-auto p-8 rounded-lg">
                {user.is_expert ? (
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 text-transparent">Your Courses</h1>
                        <Link to="/createcourse" className="flex items-center">
                            <Button variant="outlined"><AddIcon className="h-6 w-auto"/>Create Course</Button>
                        </Link>
                    </div>
                ) : (
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold">Courses</h1>
                    </div>
                )}

                <div className="grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-3 md:mt-10 mt-6">
                    {loading ? (
                        // Display skeleton while loading
                        Array.from(new Array(3)).map((_, index) => (
                            <Card key={index} sx={{ maxWidth: 385 }}>
                                <Skeleton variant="rectangular" height={140} />
                                <CardContent>
                                    <Skeleton height={30} />
                                    <Skeleton height={20} />
                                    <Skeleton height={20} width="40%" />
                                </CardContent>
                                <CardActions>
                                    <Skeleton variant="text" width="30%" />
                                </CardActions>
                            </Card>
                        ))
                    ) : (
                        courses.map((item) => (
                            <div key={item.id} className="relative border border-gray-600 shadow-lg rounded-sm">
                                <img
                                    src={item.course_image}
                                    title="Course Image"
                                />
                                <div className=" relative p-4 h-48 ">
                                    <div className="font-bold text-xl text-gray-200">
                                        {item.name}
                                    </div>
                                    <div className="text-xs text-gray-500 m-0" >
                                        {item.expert.first_name} {item.expert.last_name}
                                    </div>
                                    <div className="font-semibold mt-2 text-gray-200">
                                        <CurrencyRupeeIcon className="w-5"/>{item.price}
                                    </div>
                                    {/* <hr /> */}
                                </div>
                                <div className="absolute bottom-0 w-full grid grid-cols-2 gap-4 p-4 ">
                                    <Link className="text-sm group flex text-center justify-center items-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105" to={`/course/expertedit/${item.id}`}>
                                        <PencilSquareIcon className="w-4  group-hover:text-blue-600"/>Edit Course
                                    </Link>
                                    <Link  className="text-sm group flex text-center justify-center items-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105" size="small">
                                        <ArrowTopRightOnSquareIcon className=" w-4 group-hover:text-blue-600"/> Enrollments
                                    </Link>
                                </div>
                            </div>
                        ))
                    )}
                </div>

            </div>
        </div>
    )
}
