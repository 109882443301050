import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import myConfig from "../configs/config";
import AuthContext from "../context/AuthContext";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export function CurrentLearning() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const { authTokens, logoutUser } = useContext(AuthContext);

    useEffect(() => {
        getCurrentCourses();
    }, []);

    let getCurrentCourses = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/get-currentcources/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
        .then((res) => {
            console.log(res.data);
            setCourses(res.data);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                logoutUser();
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false); // Stop loading
        });
    }

    return (
        <div className="w-full mx-auto py-10 md:my-auto bg-black h-screen ">
            <div className="md:px-28 mx-auto p-8 rounded-lg">
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-bold text-gray-200 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-400 hover:to-pink-400">Your Courses</h1>
                </div>
                
                <div className="my-8 grid md:grid-cols-3 grid-cols-1 md:gap-12">
                    {loading ? (
                        // Display skeletons while loading
                        Array.from(new Array(3)).map((_, index) => (
                            <Card key={index} sx={{ maxWidth: 385 }}>
                                <Skeleton variant="rectangular" height={140} sx={{background:"gray"}} />
                                <CardContent>
                                    <Skeleton height={30} sx={{background:"gray"}}/>
                                    <Skeleton height={20} sx={{background:"gray"}}/>
                                </CardContent>
                                <CardActions>
                                    <Skeleton variant="text" width="50%" height={40} sx={{background:"gray"}}/>
                                </CardActions>
                            </Card>
                        ))
                    ) : (
                        courses.map((item) => (
                            <div key={item.course.id} className="relative border border-gray-600">
                                <img src={`${item.course.course_image}`} title="Course Image" className="relative object-fill" alt="Course" />
                                <div className="relative h-44 p-4">
                                    <div className="text-gray-200" >
                                    {item.course.name}
                                    </div>
                                    <div className="text-gray-400 text-xs" >
                                    {item.user.first_name} {item.user.last_name}
                                    </div>
                                    <div className="font-semibold mt-3 text-gray-200"><CurrencyRupeeIcon className=""/>{item.course.price}</div>
                                </div>
                                <div className=' absolute bottom-5 left-0 right-0'>
                                    <div className='flex justify-center items-center gap-4 mt-4'>
                                        <Link to={`/current-learning/${item.course.id}`} className="realtive p-2  bg-gray-900 text-gray-200 border border-gray-600 uppercase hover:bg-clip-text hover:text-transparent group-hover:duration-100 hover:bg-gradient-to-r hover:from-blue-500/85 hover:to-pink-500/85 ">
                                        continue learning
                                        </Link>   
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>

                {/* Uncomment if you have pagination */}
                {/* <Stack className=" inset-x-0 items-center" spacing={2}>
                    <Pagination count={10} color="primary" />
                </Stack> */}
            </div>
        </div>
    );
}
