import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { useEffect, useState } from 'react';
// Experts
import proA from "../../assests/experts/proA.png"
import sahil from "../../assests/experts/sahil.png"
import rohan from "../../assests/experts/rohan.png"
const experts = [
    { id: 1, name: "Prof. Aishwarya", expertise: "Blockchain Expert", profile: proA, linkedin: "https://www.linkedin.com/in/aish05", twitter: "", github: "", website: "" },
    { id: 2, name: "Rohan Ramteke", expertise: "Blockchain Expert", profile: rohan, linkedin: "https://www.linkedin.com/in/rohan-ramteke-ba5634237", twitter: "", github: "", website: "" },
    { id: 3, name: "Sahil Shaikh", expertise: "Blockchain Expert", profile: sahil, linkedin: "https://www.linkedin.com/in/sahilshaikhs", twitter: "", github: "", website: "" },
];

export function Expert() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100); // Delay for animation
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='items-center justify-center p-4'>
            <div>
                <h4 className='text-3xl font-bold text-center my-8 transition-transform duration-500 hover:scale-110 text-gray-200 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500'>Our Top Experts</h4>
                <div className='grid grid-cols-1 md:grid-cols-3 md:mx-4 mx-10  md:gap-12 gap-3 md:px-20'>
                    {experts.map((expert, index) => (
                        <div key={expert.id} className={`bg-gray-950 group relative border border-gray-800 rounded-md shadow-xl p-5 h-96 transition-transform duration-500 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'} delay-${index * 100} hover:scale-105 hover:shadow-lg`}>
                            <div className='relative '>
                                {/* <div className=' absolute  right-0 left-0  h-full rounded-md overflow-hidden'>
                                        <div class="absolute left-0 right-0 top-0 h-full"></div>
                                        <Meteors number={10} />
                                    <div className="relative mt-4 flex items-center justify-center bg-clip-text text-transparent bg-gradient-to-r from-gray-200 to-gray-500">
                                        <AcademicCapIcon className="h-8 w-auto text-gray-200/75" aria-hidden="true" />
                                        <div className="text-lg font-bold  "></div>
                                    </div>
                                </div> */}
                                <img src={expert.profile} alt={expert.name} className='w-36 h-36 top-5 relative mx-auto rounded-full' />
                            </div>
                            <div className='pt-10'>
                                <h4 className='text-xl font-semibold text-center mt-4 text-gray-200'>{expert.name}</h4>
                                <p className='text-center font-mono text-sm text-gray-500'>{expert.expertise}</p>
                            </div>
                            <div className=' absolute bottom-5 left-0 right-0'>
                                
                                <div className='flex justify-center items-center gap-4 mt-4'>
                                    <Link to={`expert-profile/${expert.id}`} className="realtive p-2 w-2/4 bg-gray-900 text-gray-200 border text-center border-gray-600 uppercase hover:bg-clip-text hover:text-transparent group-hover:duration-100 hover:bg-gradient-to-r hover:from-blue-500/85 hover:to-pink-500/85 ">
                                        Know More
                                    </Link>   
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}