import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import myConfig  from "../configs/config";
import AuthContext from "../context/AuthContext";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import 'react-quill/dist/quill.snow.css';
import {CartContext}  from "../context/CartContext";
import { Skeleton } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ShoppingBagIcon} from "@heroicons/react/24/outline";

export function CurrentLearningView(){
    const { addToCart } = useContext(CartContext);

    const [courses, setCourses] = useState(null);
    const [onCourse, setOnCourse] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [courseImageurl, setCourseImageurl] = useState('')

    const { CourseID } = useParams();
    const {authTokens, logoutUser, user} = useContext(AuthContext);

    // Section
    const [section, setsection] = useState([]);
    const [sectionId, setSectionId]  = useState('');
    const [title, setTitle] = useState('');
    const [descriptionsection, setDescriptionsection] = useState('');
    const [image, setImage] = useState(null);
    const [document, setDocument] = useState(null);
    const [audio, setAudio] = useState(null);
    const [video, setVideo] = useState(null);
    

    const modules = {
        toolbar: [
            [{ 'header': [1, 2,3, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];

    // Getting Course
    useEffect(() => {
        
        if (authTokens && CourseID) {
            getCourse();
            getCourseSection();
        }else{
            logoutUser();
        }
    }, [authTokens, CourseID]);

    const getCourse = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/editview/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course");
            setCourses(res.data);
            setName(res.data.name);
            setDescription(res.data.description);
            setPrice(res.data.price);
            setCourseImageurl(res.data.course_image);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };

    
    
    // Get All Section
    const getCourseSection = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course Section");
            setsection(res.data);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };

    // Get All Section
    const getCourseSectionItem = async (sectionID) => {
        const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/${sectionID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
        .then((res) => {
            console.log(res.data);
            console.log("getting Course Section");

            setTitle(res.data.title);
            setDescriptionsection(res.data.description);
            setOnCourse(false);
            setSectionId(sectionID);
        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
            console.log(error);
        })
    };
    

    return(
        <>
        {courses ?
            <div className="bg-black text-gray-200  p-8">
                <div className="md:flex w-full gap-3 md:h-screen">
                    <div className="overflow-auto no-scrollbar  md:shrink-0 md:w-8/12 rounded-xl p-5 scroll-m-2">
                        <div className="md:h-96 border border-gray-600 rounded-xl p-5 bg-gray-900 relative">
                            <img className="h-full w-fit mx-auto roun shadow-gray-100 back" src={courseImageurl}/>
                        </div>
                        {onCourse ? 
                            <div className="py-5">

                                <div className="flex justify-between items-center">
                                    <div className="font-black text-2xl w-9/12">
                                        {name}
                                    </div>
                                </div>
                                <div className="font-semibold text-xs">
                                    By {courses.expert.first_name} {courses.expert.last_name}
                                </div>
                                <br />
                                <div>
                                    <div className="mt-10 font-size:inherit; font-weight:inherit" dangerouslySetInnerHTML={{ __html: description}}>
                                    </div>
                                    {/* <ReactQuill className='rounded-lg mt-5 h-full bg-white' readOnly={true} theme={"bubble"} value={description}>
                                    </ReactQuill> */}
                                </div>
                                
                            </div>
                        :
                        
                            <div className="py-5">

                                <div className="flex justify-between items-center">
                                    <div className="font-black text-2xl w-8/12">
                                        {title}
                                        <div className="font-semibold text-xs">
                                            By {courses.expert.first_name} {courses.expert.last_name}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                    <div>
                                        <div className="mt-10 font-size:inherit; font-weight:inherit" dangerouslySetInnerHTML={{ __html: descriptionsection}}>
                                        </div>
                                        {/* <ReactQuill className='rounded-lg mt-5 h-full bg-white' readOnly={true} theme={"bubble"} value={description}>
                                        </ReactQuill> */}
                                    </div>
                                
                            </div>
                        
                        }
                    </div>
                    <div className=" w-full rounded-md p-5 border border-gray-600 shadow-2xl">
                        <div className="flex justify-between items-center ">
                            <div className="font-bold text-xl">Course Sections</div>
                            {/* <Button size="medium" ><div className="flex items-center"><AddIcon className="h-4 w-4 "/>Add new Section</div></Button> */}
                        </div>
                        <hr className="border border-gray-600" />
                        <div className=" ">
                            <List>
                            {section.map((item) => (
                                // <ListItemButton onClick={() => getCourseSectionItem(item.id.toString())}>
                                //     <ListItemText primary={item.title}/>
                                // </ListItemButton>
                                <div className="p-3 transition duration-200 hover:scale-105 hover:border hover:border-gray-600 hover:text-gray-100 rounded-sm cursor-pointer" onClick={() => getCourseSectionItem(item.id.toString())}>
                                    <div className="flex">
                                        <div className="flex-1">{item.title} </div>
                                    </div>

                                </div>
                            ))
                            }
                            </List>
                            

                        </div>
                        
                    </div>
                </div>
                
            </div>
        :<div className="p-8 bg-black">
            <div className="md:flex w-full gap-3 md:h-screen">
                <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl px-5 scroll-m-2">
                    <div className="md:h-96 border border-gray-600 rounded-xl p-5 bg-gray-800 relative">
                        <Skeleton height={300} sx={{background:"gray"}}/>
                    </div>
                    <div className="py-5">
                        <div className="flex justify-between items-center">
                            <Skeleton width="60%" height={30} sx={{background:"gray"}}/>
                            <Skeleton width={100} height={40} sx={{background:"gray"}}/>
                        </div>
                        <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                        <br />
                        <Skeleton height={20} count={3} sx={{background:"gray"}}/>
                    </div>
                </div>
                <div className="overflow-hidden w-full rounded-md border border-gray-600 shadow-2xl">
                    <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-xl transition duration-200">
                        <Skeleton width={200} height={30}sx={{background:"gray"}} />
                    </div>
                    <div className="p-5">
                        <Skeleton height={20} count={5} sx={{background:"gray"}}/>
                    </div>
                </div>
            </div>
        </div>}
        </>
    )
}