
import { UserIcon } from "@heroicons/react/20/solid";
import 'react-phone-input-2/lib/style.css'
import sahil from "../assests/experts/sahil.png"
import rohan from "../assests/experts/rohan.png"
import proA from "../assests/experts/proA.png"

const experts = [
    { id: 1, name: "Sahil Shaikh", expertise: "Blockchain Expert", profile: sahil, linkedin:"https://www.linkedin.com/in/sahilshaikhs", twitter:"", github:"", website:"" },
    { id: 2, name: "Rohan Ramteke", expertise: "Blockchain Expert", profile: rohan, linkedin:"https://www.linkedin.com/in/rohan-ramteke-ba5634237", twitter:"", github:"", website:"" },
    { id: 3, name: "Prof. Aishwarya", expertise: "Blockchain Expert", profile: proA, linkedin:"https://www.linkedin.com/in/aish05", twitter:"", github:"", website:"" },

]

export function Instructors() {

    return (
        <div className="w-full mx-auto bg-white  py-28 px-14">
            <div className="md:flex mx-auto items-center justify-center ">
                <div className="md:shrink-0 py-8 items-center justify-center bg-white rounded-md">
                    <div className="px-5">
                        <div className="bg-white h-48 px-8 ">
                                {/* <img src={profileImage} className="h-56 w-56 mx-auto z-10 rounded-full object-cover"  alt="User Profile"/> :  */}
                            <UserIcon className="h-56 w-56 mx-auto z-10 rounded-full"  alt="User Profile"/>
                        </div>
                        
                        <div className="bg-white h-48 items-center text-center  pt-8">
                            <p className="text-white  text-2xl font-bold">Name</p>
                            
                        </div>
                        
                    </div>
                    {/* <UserIcon className="h-auto w-full "  alt="Modern building architecture"/> */}
                    <div className=" p-3">
                    

                    </div>
                </div>
                <div className="px-16">
                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">name</div>
                    <a href="#" className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Change Your Profile Details</a>
                    <div className="">
                            Testing
                    </div>
                </div>
            </div>
        </div>
    )
}