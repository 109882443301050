
import './App.css';
import { NavBar } from './components/header/Navbar'; 
import { Home } from './pages/home'
import { Footer } from './components/footer/Footer';
import { AllCourses } from './pages/allcourses';
import { PageNotFound } from './pages/pagenotfound';
import { Login } from './pages/login';
import { CreateAccount } from './pages/signup';
import { Services } from './pages/services';
import { Profile } from './pages/profile';
import { Blogs } from './pages/blogs';
import { Blog1 } from './pages/bolg1';
import { Blog2 } from './pages/bolg2';
import { Blog3 } from './pages/bolg3';
import { OofBlogs } from './pages/oofBlogs';
import { CreateBlog } from './pages/createBlog';
import { ReadBlog } from './pages/blog';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import  {AuthProvider} from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import PrivateRoute from './utils/PrivateRoute';
import { AuthNavbar } from './components/header/AuthNavbar';
import { Course } from './pages/course';
import { CreateCourse } from './pages/coursecreate';
import { Webinar } from './pages/webinar';
import { BasicBlockchain } from './pages/basicBlockchain';
import { Instructors } from './pages/instructor';
import { AboutUs } from './pages/about_us';
import { CartCourse } from './pages/cart';
import { EditCourse } from './pages/editCourse';
import { OurExperts } from './pages/experts';
import { ViewCourse } from './pages/viewcourse';
import { CurrentLearning } from './pages/currentLearning';
import { CurrentLearningView } from './pages/learningViewCourse';
import ContactPage from './pages/contact';
import { OffViewCourse } from './pages/off_viewcourse';
import { Bounce, ToastContainer } from 'react-toastify';
import { ExpertProfile } from './components/experts/expertProfile';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
          />
        <CartProvider>
        <div className='sticky top-0 z-10'>
          {localStorage.getItem("authTokens")?<AuthNavbar /> : <NavBar />}
          
        </div>
        <Routes>
          <Route exact path="/" element= {<Home/>} />
          <Route exact path="/login" element={ <Login />} />
          <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute> } />
          <Route exact path="/cart" element={<PrivateRoute><CartCourse /></PrivateRoute> } />
          <Route exact path="/course/expertedit/:CourseID" element={<PrivateRoute><EditCourse /></PrivateRoute> } />

          <Route exact path="/my-learning" element={<PrivateRoute><CurrentLearning /></PrivateRoute> } />
          <Route exact path="/current-learning/:CourseID" element={<PrivateRoute><CurrentLearningView /></PrivateRoute> } />
          <Route exact path="/blogs" element={ <Blogs />} />
          <Route exact path="/blog" element={ <OofBlogs />} />
          <Route exact path="/createblog" element={<PrivateRoute><CreateBlog /></PrivateRoute> } />
          <Route exact path='/webinar' element={<Webinar />} />
          <Route exact path='/about-us' element={<AboutUs />} />
          <Route exact path='/experts' element={<OurExperts />} />
          
          <Route exact path='/sahil-shaikh/basic_blockchain' element={<BasicBlockchain />} />


          {/* Static Dynamic blog */}
          <Route exact path='/sahil-shaikh/the_blockchain_revolution' element={<Blog1 />} />
          <Route exact path='/rohan/web3' element={<Blog2 />} />
          <Route exact path='/Aishwarya/evolution_of_internet' element={<Blog3 />} />
          
          {/* Dynamic Read Blog */}
          <Route exact path='/blog/:BlogId' element={<ReadBlog/>} />

          {/* User Profile */}
          <Route exact path='/instructor/:instructorID' element={<Instructors/>} />
          <Route exact path='/expert-profile/:expertID' element={<ExpertProfile/>} />


          <Route exact path="/signup" element={ <CreateAccount />} />
          <Route exact path="/createcourse" element= {<CreateCourse/>} />
          <Route exact path="/course" element= {<Course/>} />
          <Route exact path="/course/:CourseID" element= {<ViewCourse/>} />
          <Route exact path="/blockchain-course/:CourseID" element= {<OffViewCourse/>} />
          <Route exact path="/courses" element= {<AllCourses/>} />
          <Route exact path="/contact-us" element= {<ContactPage/>} />
          <Route exact path="/services" element= {<Services/>} />
          <Route path='*' element={<PageNotFound/>} />
        </Routes>
        <Footer />
        </CartProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

