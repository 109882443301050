import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import myConfig from "../configs/config";
import AuthContext from "../context/AuthContext";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import 'react-quill/dist/quill.snow.css';
import { CartContext } from "../context/CartContext";
import { LockClosedIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import List from '@mui/material/List';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export function ViewCourse() {
    const { addToCart } = useContext(CartContext);
    const { authTokens, logoutUser, user, logoutUserPath} = useContext(AuthContext);

    const [courses, setCourses] = useState(null);
    const [onCourse, setOnCourse] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [courseImageurl, setCourseImageurl] = useState('');

    const { CourseID } = useParams();

    // Section
    const [section, setSection] = useState([]);
    const [sectionId, setSectionId] = useState('');
    const [title, setTitle] = useState('');
    const [descriptionSection, setDescriptionSection] = useState('');

    // Getting Course
    useEffect(() => {
        if (authTokens && CourseID) {
            getCourse();
            getCourseSection();
        } else {
            logoutUserPath(`/blockchain-course/${CourseID}`);
        }
    }, [authTokens, CourseID]);

    const getCourse = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/editview/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
            .then((res) => {
                setCourses(res.data);
                setName(res.data.name);
                setDescription(res.data.description);
                setPrice(res.data.price);
                setCourseImageurl(res.data.course_image);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logoutUserPath(`/blockchain-course/${CourseID}`);
                }
            });
    };

    const getCourseSection = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
        })
            .then((res) => {
                setSection(res.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logoutUserPath(`/blockchain-course/${CourseID}`);
                }
            });
    };

    // const getCourseSectionItem = async (sectionID) => {
    //     const courseUrl = `${myConfig.CRU_URL}/courses/course-insides/${CourseID}/${sectionID}/`;
    //     axios({
    //         baseURL: courseUrl,
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${authTokens.access}`
    //         },
    //     })
    //         .then((res) => {
    //             setTitle(res.data.title);
    //             setDescriptionSection(res.data.description);
    //             setOnCourse(false);
    //             setSectionId(sectionID);
    //         })
    //         .catch((error) => {
    //             if (error.response.status === 401) {
    //                 logoutUser();
    //             }
    //         });
    // };

    return (
        <>
            {courses ? (
                <div className="p-8 bg-black">
                    <div className="md:flex w-full gap-3 md:h-screen">
                        <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl p-5 scroll-m-2">
                            <div className="md:h-96 border border-gray-600 rounded-xl p-5 bg-gray-600 relative">
                                <img className="h-full w-fit mx-auto shadow-md shadow-gray-900" src={courseImageurl} alt="Course" />
                            </div>
                            {onCourse ? (
                                <div className="py-5">
                                    <div className="flex justify-between items-center">
                                        <div className="font-black text-2xl text-gray-200 w-9/12">{name}</div>
                                        <button
                                            className="py-2 px-2 text-center text-sm md:text-base border uppercase rounded-sm border-gray-600 text-gray-200   transition duration-200 hover:scale-105 font-semibold"
                                            onClick={() => addToCart(`${CourseID}`)}
                                        >
                                            <div className="flex items-center">
                                                <ShoppingBagIcon className="h-4 w-4" /> Add To cart
                                            </div>
                                        </button>
                                    </div>
                                    <div className="font-semibold text-xs text-gray-300">
                                        By {courses.expert.first_name} {courses.expert.last_name}
                                    </div>
                                    <br />
                                    <div>
                                        <div className="font-bold text-xl justify-center text-gray-200">
                                            <CurrencyRupeeIcon /> {price}
                                        </div>
                                        <div className="mt-10 text-gray-200" dangerouslySetInnerHTML={{ __html: description }}></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="py-5">
                                    <div className="flex justify-between items-center">
                                        <div className="font-black text-2xl w-8/12">{title}</div>
                                        <Button size="small" onClick={() => addToCart(`${CourseID}`)} variant="outlined">
                                            <div className="flex items-center">
                                                <ShoppingBagIcon className="h-4 w-4" /> Add To cart
                                            </div>
                                        </Button>
                                    </div>
                                    <br />
                                    <div className="mt-10 text-gray-200" dangerouslySetInnerHTML={{ __html: descriptionSection }}></div>
                                </div>
                            )}
                        </div>
                        <div className="overflow-hidden w-full rounded-md border border-gray-600 shadow-2xl">
                            <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-mg shadow-gray-500 ">
                                <div className="font-bold text-xl uppercase  hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 hover:text-transparent transition-colors duration-500 text-gray-200">Course Topics</div>
                            </div>
                            <div className="p-5">
                                <List>
                                    {section.map((item) => (
                                        <div key={item.id} className="p-3 transition duration-200 hover:scale-105 hover:border hover:border-pink-600 hover:text-red-600 rounded-sm cursor-not-allowed">
                                            <div className="flex">
                                                <div className="flex-1 text-gray-300">{item.title}</div>
                                                <LockClosedIcon className="w-4 text-gray-300" />
                                            </div>
                                        </div>
                                    ))}
                                </List>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                // Skeleton while loading
                <div className="p-8">
                    <div className="md:flex w-full gap-3 md:h-screen">
                        <div className="overflow-auto no-scrollbar md:shrink-0 md:w-8/12 rounded-xl px-5 scroll-m-2">
                            <div className="md:h-96 border-2 rounded-xl p-5 bg-gray-800 relative">
                                <Skeleton height={300} sx={{background:"gray"}}/>
                            </div>
                            <div className="py-5">
                                <div className="flex justify-between items-center">
                                    <Skeleton width="60%" height={30} sx={{background:"gray"}}/>
                                    <Skeleton width={100} height={40} sx={{background:"gray"}}/>
                                </div>
                                <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                                <br />
                                <Skeleton height={20} count={3} sx={{background:"gray"}}/>
                            </div>
                        </div>
                        <div className="overflow-hidden w-full rounded-md border-2 shadow-2xl">
                            <div className="flex p-5 justify-between items-center shadow-md rounded-md hover:shadow-xl transition duration-200">
                                <Skeleton width={200} height={30}sx={{background:"gray"}} />
                            </div>
                            <div className="p-5">
                                <Skeleton height={20} count={5} sx={{background:"gray"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
