import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import {AcademicCapIcon} from '@heroicons/react/24/outline'
import {UserIcon} from '@heroicons/react/24/outline'
import {jwtDecode} from 'jwt-decode'
import { useContext, useState, useEffect } from 'react'
import AuthContext from '../../context/AuthContext'
import { CartContext } from '../../context/CartContext'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {ShoppingBagIcon} from '@heroicons/react/24/outline'
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';





function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function AuthNavbar() {
  const { cartCount } = useContext(CartContext);

  let {user, logoutUser} = useContext(AuthContext);
  let navigation = [
      { name: 'All Courses', href: '/courses', current: false },
      { name: 'My Learning', href: '/my-learning', current: false },
      { name: 'Experts', href: '/experts', current: false },
      { name: 'Blogs', href: '/blogs', current: false },
  ]

  let expertNavigation = [
      { name: 'All Courses', href: '/courses', current: false },
      { name: 'My Learning', href: '/my-learning', current: false },
      { name: 'Experts', href: '/experts', current: false },
      { name: 'Blogs', href: '/blogs', current: false },
      { name: 'Create Blogs', href: '/createblog', current: false },
      { name: 'Course', href: '/course', current: false },
  ]


  if(user){
      if(user.is_expert === true){
          navigation = expertNavigation;
      }
  }

  return (
    <Disclosure as="nav" className="bg-black shadow-md">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="">
                <Link to="/courses" className="w-9 group bg-transparent transition-colour duration-300">
                  <div className="flex items-center gap-x-1  text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-400 group-hover:to-pink-400">
                    <AcademicCapIcon className="w-8 text-blue-400" aria-hidden="true" />
                    <div className="text-sm font-bold leading-6 "> BlockChain Pathshala</div>
                  </div>
                
                </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex items-center justify-center space-x-10">
              {/* <div className="flex  "> */}
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className=' text-xs font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent  hover:border-pink-600 ease-in-out hover:scale-125'
                    aria-current={item.current ? 'page' : undefined}
                    // className={classNames(
                    //   item.current ? 'bg-blue-400 text-white' : ' border border-white text-blue-400 hover:border hover:border-blue-400 hover:text-blue-400',
                    //   'rounded-sm px-3 py-2 text-sm font-medium',
                    // )}
                  >
                    {item.name}
                  </Link>
                ))}
              {/* </div> */}
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

            <Link to="/cart" type="button" className="relative   p-1 text-gray-200 ">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Cart </span>
              <Badge badgeContent={cartCount} color="primary">
                <ShoppingBagIcon aria-hidden="true" className="h-6 w-6" />
              </Badge>
            </Link>
            {/* <Link type="button" className="relative   p-1 text-gray-200">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-6 w-6" />
            </Link> */}
            {/* Profile dropdown */}
            <Menu as="div" className="relative ">
              <div>
                <MenuButton className="relative flex rounded-full text-sm ">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open User Menu</span>
                  {/* <img
                    alt=""
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    className="h-8 w-8 rounded-full"
                  /> */}
                  {user.user_profile? <img className='h-8 w-8 object-cover rounded-full' src={user.user_profile}/>:
                  <UserIcon className="h-6 w-6  text-gray-300 hover:text-gray-400" />
                  }
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg  ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                    Your Profile
                  </Link>
                </MenuItem>
                {/* <MenuItem>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                    Settings
                  </a>
                </MenuItem> */}
                <MenuItem>
                  <button  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 w-full text-left" onClick={logoutUser}>
                    Sign out
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <Link
              key={item.name}
              as="a"
              to={item.href}
              aria-current={item.current ? 'page' : undefined}
              className={classNames(
                item.current ? 'bg-white text-blue-600' : 'text-gray-400 hover:bg-gray-900 hover:text-white',
                'block rounded-sm px-3 py-2 text-base font-medium',
              )}
            >
              {item.name}
            </Link>
          ))}
          {expertNavigation.map((item) => (
            <Link
              key={item.name}
              as="a"
              to={item.href}
              aria-current={item.current ? 'page' : undefined}
              className={classNames(
                item.current ? 'bg-white text-blue-600' : 'text-gray-400 hover:bg-gray-900 hover:text-white',
                'block rounded-sm px-3 py-2 text-base font-medium',
              )}
            >
              {item.name}
            </Link>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}
