import { UserIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from "../context/AuthContext";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Meteors } from "../components/animation/meteor";

export function Login() {
    let { loginUser } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Start loading
        try {
            await loginUser(event);
        } finally {
            setLoading(false); // Stop loading after login process completes
        }
    };

    return (
        <div className=" relative bg-black">
            <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <div className="h-screen">
                <div className=" relative mx-auto max-w-2xl pt-28 ">
                    <Meteors number={8} />
                    <div className="text-center">
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                            <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-200 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                                LOGIN
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="flex relative flex-col justify-center px-6 lg:px-8">
                    <div className="mt-10 sm:mx-auto md:pb-0 pb-52 sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={handleLoginSubmit}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200 uppercase">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        className="block w-full rounded-sm border-0 py-1.5 px-1.5 bg-gray-100/20  text-gray-300  placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-200 uppercase">
                                        Password
                                    </label>
                                    <div className="text-sm">
                                        <a href="#" className="font-semibold text-gray-400  hover:text-gray-500 hover:scale-110 transition duration-150">
                                            Forgot password?
                                        </a>
                                    </div>
                                </div>
                                <div className="relative mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        required
                                        autoComplete="current-password"
                                        className="block w-full rounded-sm border-0 py-1.5 px-1.5 bg-gray-100/20  text-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none "
                                    >
                                        {showPassword ? (
                                            <EyeIcon className="w-5 h-5 text-gray-200" />
                                        ) : (
                                            <EyeSlashIcon className="w-5 h-5 text-gray-200" />
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={loading} // Disable button while loading
                                    className={`flex w-full justify-center rounded-sm uppercase overflow-hidden border border-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white transition duration-200 hover:scale-105 hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 hover:text-transparent ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {loading ? (
                                        <span className=" uppercase">Loging In...</span> // Show loading text
                                    ) : (
                                        'Sign in'
                                    )}
                                </button>
                            </div>
                        </form>

                        <p className="md:mt-10 text-center text-sm text-gray-500">
                            Not a Member?{' '}
                            <Link to="/signup" className="font-semibold text-gray-400 hover:text-gray-200 transition duration-150 hover:scale-105">
                                Create Account and Start Learning
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
