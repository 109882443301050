import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";


import sahil from "../assests/experts/sahil.png"
import webinar from "../assests/images/webinar.png"

import { CalendarIcon } from "@heroicons/react/20/solid";
import {MapPinIcon} from "@heroicons/react/20/solid";

export function BasicBlockchain() {

    
    return(
        <div className="w-full mx-auto py-10 bg-black">
            <div className="relative md:w-5/6 grid md:grid-cols-3 gap-4 mx-auto  p-8 rounded-lg ">
                <div className="  rounded-xl shadow-2xl p-5 border border-gray-600">
                    <div className=" relative p-4">
                        <img src={sahil} className="h-auto w-full object-fit rounded-3xl"  alt="User Profile"/> 
                        <div className=" absolute mx-4 inset-x-0 bottom-0 text-center bg-gray-700 text-gray-300 uppercase font-mono rounded-b-md p-1 z-10 shadow-lg">
                            BlockChain Expert
                        </div>
                    </div>

                    <div className="relative rounded-lg mt-5 p-5 text-gray-200">
                        <div className="grid grid-cols-2 mb-8">
                            <div >Ticket Cost:</div>
                            <div className=" text-right font-bold">Free</div>
                        </div>
                        <hr  className="my-4"/>
                        <div className="grid grid-cols-2 mb-5">
                            <div  className="flex"><CalendarIcon className="w-4 " />Date:</div>
                            <div className=" text-right  font-bold"></div>
                        </div>
                        <hr  className="my-4"/>
                        <div className="flex items-center justify-center">
                            <div  className="flex-1 flex"><MapPinIcon className="w-4" />Location:</div>
                            <div className=" text-right ">BlockChain Pathshala</div>
                        </div>

                    </div>

                </div>
                <div className=" md:col-span-2 bg-transparent border border-gray-600 text-gray-200 rounded-lg p-10">
                    <img src={webinar} className=" object-fit w-full h-60 rounded-md" />
                    <div className="my-5">
                        <div className="font-bold text-3xl">
                            Basic of BlockChain
                        </div>
                        <div className="font-bold text-xl">
                            Point of Speaking
                        </div>
                    </div>
                    <div className="text-gray-400 mb-5 font-semibold">
                        Why need of BlockChain?<br/>
                        History of BlockChain<br/>
                        Bitcoin whitepaper<br/>
                        Proof of Work<br/>
                        Double spending<br/>
                        Nonce <br/>
                        Hash
                    </div>

                    <Link to={`/login`} className=" border border-gray-600 px-4 py-2 rounded-sm">Register Now</Link>

                </div>
            
            </div>
        </div>
    )
}