import React, {useContext, useState} from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import myConfig from '../configs/config';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export function CreateCourse() {
    const [value, setValue] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const navigate = useNavigate();

    const {authTokens, logoutUser} = useContext(AuthContext);


    
    const modules = {
        toolbar: [
            [{ 'header': [1, 2,3] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link',],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 
    ];

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          setSelectedFile(file);
    
          // Create a preview URL for the image
          const imageUrl = URL.createObjectURL(file);
          setPreviewUrl(imageUrl);
        }
      };
    
    // Submit blog
    const handleSubmit = () => {
        const blogUrl = `${myConfig.CRU_URL}/courses/`;
        console.log(title, value, selectedFile);
        if ( name === '' || value === '' || selectedFile === null || price == null) {
            console.log('Please fill all fields');
            console.log(title, value, selectedFile);
            alert('Please fill all fields');
            return;
        }
        let form_data = new FormData();
        form_data.append('name', name);
        form_data.append('description', value);
        form_data.append('course_image', selectedFile);
        form_data.append('price', price);
        form_data.append('user', String(authTokens.access));
        console.log(form_data);
        axios({
            baseURL: blogUrl,
            method: 'POST',
            headers: {
                'Content-Type':'multipart/form-data',
                'Authorization': `Bearer ${authTokens.access}`
            },
            data: form_data
        })
        .then((res) => {
            console.log(res.data);
            if(res.status === 200){
                console.log("Blog Finally Done")
                navigate('/course');
            }else if(res.statusText === 'Unauthorized'){
                logoutUser();
            }

        })
        .catch((error) => {
            if (error.response.status === 401) {
                // handle 401 error here
                logoutUser();
            }
        })

    }

    return (
        <div >
            <h1 className="text-2xl w-full text-center font-bold text-black md:mx-12 md:text-left">Create New Course</h1>
            <div className="md:flex md:m-12 p-8 rounded-md ">
                <div className='md:shrink-0 md:w-1/4 w-full'>
                    {previewUrl ? (
                        <img src={previewUrl} id='course_poster' className="md:h-56 md:w-full w-72 h-72 mx-auto z-10 rounded-sm object-fit"  alt="Course Poster"/>
                            // <img
                            // src={previewUrl}
                            // alt="Profile Preview"
                            // style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                            // />
                    ) : (
                        <p>No image selected</p>
                    )}
                    {/* <input 
                        className="block w-auto text-sm text-gray-900 border border-gray-300 rounded-lg p-2 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" 
                        id="multiple_files" 
                        name="multiple_files"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        type="file" /> */}
                    
                </div>
                <div className="w-full h-auto md:px-16 md:mt-auto mt-10 ">
                    <Box component=""  className="my-2"  noValidate autoComplete="off">
                        <TextField type="text" placeholder="Course Name" label="Enter Course Name" className="" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    </Box>
                    <Box component=""  className="my-2 flex gap-6"  noValidate autoComplete="off">
                        <TextField type="number" placeholder="Course Price" label="Enter Course Price ₹" className=" flex-1" value={price} onChange={(e) => setPrice(e.target.value)} />
                        <Button className='md:mb-10 flex-1' component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<AddPhotoAlternateIcon />}>
                            Select Course Poster
                            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                        </Button>
                    </Box>
                    <label htmlFor="" className="block text-sm font-semibold mt-6 leading-6 text-gray-900 ">
                        Write Course Description 
                    </label>
                    <ReactQuill className=' relative rounded-lg bg-white' formats={formats} modules={modules} theme="snow" value={value} onChange={setValue}>
                        
                    </ReactQuill>
                    <div className="flex justify-end mt-8 ">
                        <button className=" bg-blue-600  px-5 py-2 rounded-md text-white " onClick={handleSubmit}>Create</button>
                    </div>
                </div>
            </div>
        </div>
    )
}