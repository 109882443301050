import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firstblog from "../assests/images/firstblog.png"
import {ReactTyped} from 'react-typed';

import sahil from "../assests/experts/sahil.png"
import webinar from "../assests/images/webinar.png"


export function Webinar() {

    
    return(
        <div className=" relative w-full bg-black py-10">
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            {/* <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
                >
                <defs>
                    <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="10%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                    >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="10%" y={-1} className="overflow-visible fill-blue-50">
                    <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div> */}
            
            <div className=" relative mx-auto max-w-2xl py-28 ">
                <div className="text-center ">
                    <div className="flex items-center justify-center">
                        <h4 className="text-3xl  font-bold w-fit text-gray-200 sm:text-5xl uppercase transition-transform ease-in-out duration-300 hover:scale-125 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                            Webinars
                        </h4>
                    </div>
                    <p className="animate-typing mt-6 md:text-lg text-base leading-8 text-gray-300 font-mono px-4">
                        <ReactTyped strings={
                        ["Unlock the Future with Blockchain: Master DeFi, DAOs, NFTs, and More!"]
                        } typeSpeed={50}  />
                        
                    </p>
                    
                </div>
            </div>
            <div className="md:w-3/4  mx-auto md:p-8 p-6 rounded-md ">
                
                <div className="relative group shadow-2xl transition duration-300 ease-in-out hover:scale-105">
                     <div className="absolute -inset-1 -z-10 animate-gradient rounded-sm blur opacity-55 group-hover:opacity-100 hidden group-hover:block	"></div>
                        <div className=" rounded-lg md:p-10 p-5 border border-gray-600 backdrop-blur-md bg-transparent/35">
                            <div className=" w-full">
                                <img className="object-fit w-full md:h-72 h-56 rounded-sm" src={webinar} /> 
                            </div>
                            <div className=" justify-between mt-10 items-center mb-2">
                                <div className="flex items-center gap-x-4">
                                    <div className="w-12 h-12 rounded-full  flex justify-center items-center overflow-hidden">
                                    <img className="h-12 w-12 rounded-full object-cover" src={sahil} />
                                    </div>
                                    <div>
                                        <h1 className=" md:text-3xl text-2xl font-semibold text-gray-200 ">Basic of BlockChain</h1>
                                        <p className="text-sm text-gray-600">Speaker: <span className="font-semibold">Sahil Shaikh</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Link to={`/sahil-shaikh/basic_blockchain`} className="border border-gray-600 text-gray-200 bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r  group-hover:from-blue-500 group-hover:to-pink-500 px-4 py-2 rounded-sm ">Know More</Link>
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
    )
}