import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import myConfig from "../configs/config";
import AuthContext from "../context/AuthContext";
import { CartContext } from "../context/CartContext";
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { ArrowTopRightOnSquareIcon, ShoppingBagIcon } from "@heroicons/react/24/outline";
import RemoveShoppingCartRoundedIcon from '@mui/icons-material/RemoveShoppingCartRounded';
import Skeleton from '@mui/material/Skeleton';
import {ReactTyped} from 'react-typed';
import { green } from "@mui/material/colors";

export function AllCourses() {
    const { addToCart, removeFromCart } = useContext(CartContext);
    const [courses, setCourses] = useState([]);
    const [existed_course, setExistedCourses] = useState([]);
    const [cart_course, setCart_course] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    const { authTokens, logoutUser } = useContext(AuthContext);

    useEffect(() => {
        if(authTokens){
            getCourses();
        }else{
            getCoursesForAll();
        }
    }, []);

    const getCourses = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/allcourse/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        })
        .then((res) => {
            console.log(res.data);
            setCourses(res.data.course_data);
            setExistedCourses(res.data.existed_course);
            setCart_course(res.data.cart_course);
            setLoading(false);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                logoutUser();
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false); // Stop loading
        });
    };
    const getCoursesForAll = async () => {
        const courseUrl = `${myConfig.CRU_URL}/courses/allcourse/`;
        axios({
            baseURL: courseUrl,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then((res) => {
            console.log(res.data);
            setCourses(res.data.course_data);
            setLoading(false);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                logoutUser();
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false); // Stop loading
        });
    };

    return (
        <div className="relative w-full bg-black text-gray-300 ">
            
            <div className="absolute  duration-1000 animate-pulse bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_2px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div className="absolute left-0 right-0 top-0 h-screen w-full rounded-3xl bg-[radial-gradient(circle_250px_at_50%_200px,#fbfbfb36,#000)]"></div>
            <div className=" relative  py-28 ">
                <div className="text-center flex items-center justify-center">
                    <h4 className="font-bold uppercase transition-transform duration-300 w-fit  hover:scale-110 tracking-wide text-5xl hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                    Pick Your<br/> 
                    Professional Course 
                    </h4>
                    {/* <p className="animate-typing mt-6 text-lg leading-8 text-gray-600 font-mono px-4">
                        <ReactTyped strings={
                        [
                            "In the fast-paced world of technology, there's a term that has been making waves..",
                            "In recent years, you might have heard the term 'Web3' buzzing around in tech circles...",
                            "The internet has come a long way since its inception. Over the years, it has gone..."
                        ]
                        } typeSpeed={50} loop  />
                        
                    </p> */}
                    
                </div>
            </div>
            <div className="md:px-28 p-5 mx-auto">
                
                <div className=" grid md:grid-cols-3 grid-cols-1 md:gap-0 gap-3">
                    {loading ? (
                        // Display skeletons while loading
                        Array.from(new Array(3)).map((_, index) => (
                            <Card key={index} sx={{ maxWidth: 385, background:"transparent" }} className="relative group border bg-transparent border-gray-700 rounded-md overflow-hidden">
                                <Skeleton variant="rectangular" animation="wave" height={140} sx={{background:"gray"}} />
                                
                                <CardContent>
                                    <Skeleton height={30} sx={{background:"gray"}}/>
                                    <Skeleton height={20} width={100} sx={{background:"gray"}}/>
                                    <Skeleton height={20} width={82} sx={{background:"gray"}}/>
                                </CardContent>
                                <div className="mx-auto flex gap-2 justify-center py-4">
                                    <Skeleton variant="text" width="45%" height={40} sx={{background:"gray"}}/>
                                    <Skeleton variant="text" width="45%" height={40} sx={{background:"gray"}}/>
                                </div>
                            </Card>
                        ))
                    ) : (
                        <>
                        {authTokens ? (
                            courses.map((item) => (
                                <div key={item.id} className="relative group border border-gray-700 rounded-md overflow-hidden" >
                                    <img
                                    src={item.course_image}
                                    title="Course Image"
                                    />
                                    <div className="top-8 -right-9 rotate-45 absolute flex justify-center">
                                        {!existed_course.includes(item.id) ?? 
                                            <div className="relative">
                                            <div className="absolute -inset-1 animate-gradient rounded-full blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
                                            <div className="relative px-9 py-1 text-xs uppercase leading-6 text-gray-600 bg-white/85">
                                                <span className="absolute inset-0" aria-hidden="true" />
                                                5% off Join Now
                                            </div>
                                            </div>
                                        }
                                    </div>
                                    <div className=" relative p-4 h-48 ">
                                        <div className="font-bold text-xl ">
                                            {item.name}
                                        </div>
                                        <div className="text-xs text-gray-500 m-0" >
                                            {item.expert.first_name} {item.expert.last_name}
                                        </div>
                                        <div className="font-semibold mt-2 ">
                                            <CurrencyRupeeIcon className="w-5"/>{item.price}
                                    </div>
                                    </div>
                                    <div className="absolute bottom-0 w-full grid grid-cols-2 gap-1 p-4  ">
                                        {existed_course.includes(item.id) ? (
                                            <Link to={`/current-learning/${item.id}`} className="text-sm flex text-center items-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105">Continue Learning</Link>
                                        ) : cart_course.includes(item.id) ? (
                                            <button className=" text-sm flex text-center items-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105"  onClick={() => removeFromCart(item.id)}>
                                                <RemoveShoppingCartRoundedIcon className="w-5 group-hover:text-blue-500/75" /> Remove 
                                            </button>
                                        ) : (
                                            <button className=" text-sm flex text-center items-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105"  onClick={() => addToCart(item.id)}>
                                                <ShoppingBagIcon className="w-4 group-hover:text-blue-500/75" />Add To Cart
                                            </button>
                                        )}
                                        {!existed_course.includes(item.id) && (
                                            <Link to={`/course/${item.id}`} className="text-sm flex text-center justify-center items-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105">
                                                <ArrowTopRightOnSquareIcon className="w-5 group-hover:text-blue-500/75 "/>Know More
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            ))
                        ):(
                        courses.map((item) => (
                            <div key={item.id} className="relative group border border-gray-700 rounded-md overflow-hidden">
                                <img
                                    src={item.course_image}
                                    title="Course Image"
                                />
                                <div className="top-8 -right-9 rotate-45 absolute flex justify-center">
                                    <div className="relative">
                                    <div className="absolute -inset-1 animate-gradient rounded-full blur opacity-75 group-hover:opacity-100 transition duration-75 group-hover:duration-100 bg-gradient-to-r from-blue-500 to-pink-500"></div>
                                    <div className="relative px-9 py-1 text-xs uppercase leading-6 text-gray-600 bg-white/85">
                                        <span className="absolute inset-0" aria-hidden="true" />
                                        5% off Join Now
                                    </div>
                                    </div>
                                </div>
                                <div className=" relative p-4 h-48 ">
                                    <div className="font-bold text-xl ">
                                        {item.name}
                                    </div>
                                    <div className="text-xs text-gray-500 m-0" >
                                        {item.expert.first_name} {item.expert.last_name}
                                    </div>
                                    <div className="font-semibold mt-2 ">
                                        <CurrencyRupeeIcon className="w-5"/>{item.price}
                                    </div>
                                </div>
                                <div className="absolute bottom-0 w-full grid grid-cols-2 gap-4 p-4 ">
                                    <Link className="flex-1 text-sm flex text-center justify-center  p-2 w-full rounded-sm  border border-gray-600 text-gray-300 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105" to={`/course/expertedit/${item.id}`}>
                                        Start Learning
                                    </Link>
                                    <Link to={`/blockchain-course/${item.id}`} target="_blank" className="flex-1 group text-sm flex border border-gray-600 text-center justify-center items-center p-2 w-full rounded-sm group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500 font-semibold transition-transform duration-150 ease-in-out uppercase hover:scale-105" size="small">
                                        <ArrowTopRightOnSquareIcon className=" w-5 group-hover:text-blue-500/75"/> Know More
                                    </Link>
                                </div>
                            </div>
                        ))
                        )}
                        
                    </>
                    )}
                    
                </div>
            </div>
        </div>
    );
}
