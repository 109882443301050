import { Fragment, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import {
  AcademicCapIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, } from '@heroicons/react/20/solid'

const products = [
  { name: 'About Us', description: 'Get a better understanding about Blockchain Pathshala', href: '/about-us', icon: AcademicCapIcon },
  { name: 'Contact', description: 'Connect directly to Us', href: '/contact-us', icon: PhoneIcon },
  
]
const callsToAction = [
  // { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  // { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className=" sticky top-0 left-0 right-0 z-50 pt-2 backdrop-blur-sm bg-black  ">
      <nav className=" flex  items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex  lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 group bg-transparent text-gray-200 transition-transform duration-300  hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
            <div className="flex items-center gap-x-1.5">
              <AcademicCapIcon className="h-8 w-auto group-hover:text-blue-500/75" aria-hidden="true" />
              <span className="text-lg font-bold leading-6 ">Blockchain Pathshala</span>
            </div>
            
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          

          <Link to="/" className="text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent  hover:border-pink-600 ease-in-out hover:scale-125">
            Home
          </Link>
          <Link to="/courses" className="text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent hover:border-pink-600 ease-in-out hover:scale-125">
            Course
          </Link>
          <Link to="/blog" className="text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent  hover:border-pink-600 ease-in-out hover:scale-125">
            Blog
          </Link>
          <Link to="/webinar" className="text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent hover:border-pink-600 ease-in-out hover:scale-125">
          Webinar
          </Link>
          <Link to="/services" className="text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent hover:border-pink-600 ease-in-out hover:scale-125">
          Services
          </Link>
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold  text-gray-200 uppercase transition-all duration-100 border-b-2 border-transparent hover:border-pink-600 ease-in-out hover:scale-125">
              About Us
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-200" aria-hidden="true" />
            </PopoverButton>

            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-md bg-black shadow-lg ring-1 ring-gray-600">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative overflow-hidden flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 "
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-300 group-hover:bg-white/40">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-white" aria-hidden="true" />
                        <span className="absolute top-10 -z-10 h-5 w-5 rounded-full bg-gradient-to-r from-black from-40% to-gray-700/70 transition-all duration-300 group-hover:scale-[40]"></span>
                      </div>
                      <div className="flex-auto">
                        <Link to={item.href} className="block font-semibold text-gray-300 group-hover:text-white uppercase">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-gray-600 group-hover:text-white">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link to="/login" className=" relative text-sm font-semibold text-gray-200 uppercase transition-all duration-500 ease-in-out hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                
                <Link to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600 " onClick={() => setMobileMenuOpen(false)}
                >
                  Home  
                </Link>
                <Link to="/courses"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600" onClick={() => setMobileMenuOpen(false)}
                >
                  Course
                </Link>
                <Link to="/blog"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600" onClick={() => setMobileMenuOpen(false)}
                >
                  Blog
                </Link>
                <Link to="/webinar"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600" onClick={() => setMobileMenuOpen(false)}
                >
                  Webinar
                </Link>
                <Link
                  to="/services"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600" onClick={() => setMobileMenuOpen(false)}
                >
                  Services
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600" >
                        About Us
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Link
                            key={item.name}
                            as="a"
                            to={item.href}
                            className="block relative rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-200 hover:bg-gray-600"
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>
              </div>
              <div className="py-6">
                <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-600"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
