import { AcademicCapIcon } from "@heroicons/react/20/solid"
import { Link, Navigate, useNavigate  } from "react-router-dom"
import myConfig from "../configs/config"
import { Bounce, ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import { useState } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Meteors } from "../components/animation/meteor";

export function CreateAccount() {
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [referCode, setReferCode] = useState("");
    const [terms, setTerms] = useState(false);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [inputErrors, setInputErrors] = useState({
        first_name: true,  // Default to true for red border initially
        last_name: true,
        email: true,
        phone_number: true,
        password: true,
        confirmPassword: true,
    });

    // Dialog
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Password validation function
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    // Validate input fields (returns true if valid, false if not)
    const validateInput = (name, value) => {
        if (name === "password") {
            return validatePassword(value);  // Validate password based on criteria
        } else if (name === "confirmPassword") {
            return value === password && value !== "";  // Passwords should match
        }
        return value !== "";  // Basic validation: not empty
    };

    // Handle field changes and validation
    const handleInputChange = (name, value) => {
        const isValid = validateInput(name, value);
        setInputErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !isValid  // Set to true if invalid, false if valid
        }));

        // Update the corresponding state
        switch (name) {
            case "first_name":
                setFirstName(value);
                break;
            case "last_name":
                setLastName(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "phone_number":
                setPhoneNumber(value);
                break;
            case "password":
                setPassword(value);
                break;
            case "confirmPassword":
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSave = (e) => {
        e.preventDefault();
    
        const hasErrors = Object.values(inputErrors).some((error) => error);
    
        if (hasErrors) {
            toast("Please correct the highlighted fields.");
            setLoading(false); // Ensure loading is set to false if there are errors
            return;
        }
    
        if (!terms) {
            toast("Please accept the terms and conditions.");
            setLoading(false); // Ensure loading is set to false if terms are not accepted
            return;
        }
    
        setLoading(true); // Set loading only if there are no immediate errors
    
        const SIGNUP_URL = `${myConfig.CRU_URL}/account/register/`;
        axios({
            baseURL: SIGNUP_URL,
            method: "POST",
            data: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone_number: phone_number,
                password: password,
                password2: confirmPassword,
                refferal_code: referCode,
                terms: true,
            },
        })
        .then((res) => {
            if (res.status === 200) {
                // toast("Account created successfully. Kindly verify your email");
                toast.success('Account created successfully. Kindly verify your email', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
        })
        .catch((error) => {
            console.log("ERROR", error);
            let error_response = error.response.data;
            if (error_response.password) {
                toast.warn('Weak Password.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            } else {
                toast.warn('Email or Password is incorrect. Please try again.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }
        })
        .finally(() => {
            setLoading(false); // Ensure loading is reset regardless of success or failure
        });
    };
    

    const inputClass = (error) =>
        error ? "border-red-800 focus:ring-2 focus:ring-inset focus:ring-red-800" : "border-green-800 focus:ring-2 focus:ring-inset focus:ring-green-500";  // Default red, turn green if valid

    return (
        <section className="relative bg-black">
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
            <div className="flex relative flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0">
                <Link to={"#"} className="flex group relative items-center mb-6 text-4xl font-bold tracking-tight text-gray-200 transition-transform duration-300 hover:scale-110 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                    <AcademicCapIcon className="md:h-8 h-16 w-auto text-gray-300 group-hover:text-blue-500" aria-hidden="true" />
                    BlockChain Pathshala
                </Link>
                    <Meteors />
                <div className="relative bg-tranparent/10 backdrop-blur-sm border border-gray-600 rounded-md md:w-3/4">
                    {/* <div className="absolute -inset-2 -z-10 animate-gradient rounded-sm blur opacity-45 transition-transform duration-75"></div> */}
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-200 md:text-2xl dark:text-gray-200">
                            Create New Account
                        </h1>
                        <form className=" " onSubmit={handleSave}>
                            <div className="md:grid md:grid-cols-2 gap-6 justify-center">
                                <div>
                                    <label htmlFor="first_name" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">First Name</label>
                                    <input type="text" name="first_name" id="first_name" className={` bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.first_name)}`} placeholder="First Name" value={first_name} onChange={(e) => handleInputChange("first_name", e.target.value)} required/>
                                </div>
                                <div>
                                    <label htmlFor="last_name" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Last Name</label>
                                    <input type="text" name="last_name" id="last_name" className={`bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.last_name)}`} placeholder="Last Name" value={last_name} onChange={(e) => handleInputChange("last_name", e.target.value)} required/>
                                </div>
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Your Email</label>
                                    <input type="email" name="email" id="email" className={`bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.email)}`} placeholder="xyz@mail.com" value={email} onChange={(e) => handleInputChange("email", e.target.value)} required/>
                                </div>
                                <div>
                                    <label htmlFor="phone_number" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Your Phone Number</label>
                                    <input type="tel" name="phone_number" id="phone_number" className={`bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.phone_number)}`} placeholder="91.........." value={phone_number} onChange={(e) => handleInputChange("phone_number", e.target.value)} required/>
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Password</label>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className={`bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.password)}`} value={password} onChange={(e) => handleInputChange("password", e.target.value)} required/>
                                    <small className="text-gray-300">Password must be at least 8 characters long and include uppercase, lowercase, number, and special character.</small>
                                </div>
                                <div>
                                    <label htmlFor="confirm-password" className="block mb-2 text-sm text-gray-200 uppercase font-semibold">Confirm password</label>
                                    <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className={`bg-gray-100/20 border text-gray-300 text-sm rounded-sm  block w-full p-2.5 ${inputClass(inputErrors.confirmPassword)}`} value={confirmPassword} onChange={(e) => handleInputChange("confirmPassword", e.target.value)} required/>
                                </div>
                            </div>
                            <div className="flex items-center justify-center md:mt-0 mt-5">
                                <div className="space-y-4">
                                    <div className="flex items-center h-5">
                                        <input id="terms" name="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 " value={terms} onChange={(e) => setTerms(e.target.checked)} required/>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="terms" className="font-light text-gray-300 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" onClick={handleClickOpen}>Terms and Conditions</a></label>
                                        </div>
                                    </div>
                                    <button 
                                        type="submit" 
                                        className=" w-full text-gray-200 border border-gray-500 hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500 hover:text-transparent  transition-all duration-500 hover:scale-110 rounded-sm text-sm px-5 py-2.5 text-center hover:font-bold"
                                        disabled={loading}>
                                            
                                        {loading ? ("Creating Account Please Wait..."):("Create Account")}
                                        </button>
                                    <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                        Already have an account? <Link to="/login" className="font-medium text-gray-400 hover:underline dark:text-gray-200">Login here</Link>
                                    </p>
                                </div>
                            </div>
                            <Dialog
                            open={open}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="border border-gray-600"
                                >
                                    <DialogTitle id="alert-dialog-title" className="bg-black">
                                        <div className="font-bold bg-black text-gray-200">Tearms ans Condition</div>
                                    </DialogTitle>
                                    <hr className="border-gray-600" />
                                    <div className="overflow-y-scroll p-5 bg-black">
                                        <div className="bg-black py-10 px-4 sm:px-6 lg:px-8">
                                            <div className="max-w-4xl mx-auto bg-black shadow-md rounded-lg p-8">
                                                <h1 className="text-3xl font-bold mb-6 text-center text-gray-200">Terms and Conditions</h1>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">1. Introduction</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        Welcome to Blockchain Pathshala ("we", "our", "us"). By accessing or using our website and services (collectively, the "Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, you may not use the Service.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">2. Use of the Service</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        You agree to use the Service only for lawful purposes and in accordance with these Terms. You must not use the Service in any way that could damage, disable, overburden, or impair the Service, or interfere with any other party's use of the Service.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">3. Account Registration</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        To access certain features of the Service, you may need to register for an account. You must provide accurate and complete information during the registration process and keep your account information updated. You are responsible for safeguarding your account credentials and for all activities that occur under your account.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">4. Courses and Content</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        All courses and content provided by Blockchain Pathshala are for educational purposes only. We make no guarantees regarding the results or outcomes from using our courses and content.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">5. Payment and Refund Policy</h2>
                                                    <h3 className="text-lg font-semibold text-gray-400 mt-4">Payment</h3>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        All payments for courses must be made in advance. We accept various payment methods as indicated on our website.
                                                    </p>
                                                    <h3 className="text-lg font-semibold text-gray-200 mt-4">Refund Policy</h3>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        We offer a 7-day refund policy for all courses. If you are not satisfied with a course, you may request a refund within 7 days of purchase. To request a refund, please contact our support team at the provided support email. Refunds will be processed within 15-30 business days.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">6. Placement Support and Assistance</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        For participants of our Full-Stack Program, Blockchain Pathshala offers placement support and assistance for a period of 3 months after the completion of the program. This includes:
                                                    </p>
                                                    <ul className="list-disc list-inside mt-2 text-gray-400 leading-relaxed">
                                                        <li><strong>Guaranteed Internship:</strong> Participants are guaranteed an internship placement for a duration of 3 months.</li>
                                                        <li><strong>Stipend:</strong> The stipend for the internship is determined by the company providing the internship.</li>
                                                    </ul>
                                                    <p className="text-gray-400 mt-4">
                                                        Blockchain Pathshala does not guarantee full-time employment post-internship. The terms of the internship, including responsibilities, working hours, and other conditions, are determined by the company offering the internship.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">7. Intellectual Property</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        All content, including but not limited to text, graphics, images, and software, is the property of Blockchain Pathshala or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written permission.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">8. Limitation of Liability</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        To the fullest extent permitted by law, Blockchain Pathshala shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
                                                    </p>
                                                    <ul className="list-disc list-inside mt-2 text-gray-400 leading-relaxed">
                                                        <li>Your use or inability to use the Service.</li>
                                                        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
                                                    </ul>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">9. Governing Law</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Sangamner, Maharashtra.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">10. Changes to Terms</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
                                                    </p>
                                                </div>

                                                <div className="mb-6">
                                                    <h2 className="text-xl font-semibold text-gray-200 mb-2">11. Contact Us</h2>
                                                    <p className="text-gray-400 leading-relaxed">
                                                        If you have any questions about these Terms, please contact us.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-gray-600"/>
                                    <DialogActions className="bg-black">
                                        <Button onClick={(e) => {handleClose(); setTerms(true); }}  color="primary" variant="contained">accept</Button>
                                        <Button onClick={handleClose}  color="error" variant="outlined">Cancel</Button>
                                    </DialogActions>
                                </Dialog>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
