import { Link } from "react-router-dom"

export function PageNotFound() {
    return (
      <div className=" relative bg-black">
        <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
        <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
        <main className="grid relative min-h-full place-items-center bg-transparent px-6 py-64 sm:py-56 lg:px-8">
          <div className="text-center ">
            <div className="flex items-center justify-center">
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-transparent w-fit bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500">404 <br/>Page not found </h1>
            </div>
            <p className="mt-6 text-base leading-7 text-gray-400">Sorry, we couldn’t find the <strong className="">bit</strong> you’re looking for.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Link
                to={"/"}
                className="rounded-sm px-3.5 py-2.5 text-sm font-semibold text-gray-200 border border-gray-300 transition duration-200 hover:scale-110 "
              >
                Go back home
              </Link>
              <Link to={"/contact-us"} className="text-sm font-semibold text-gray-200 hover:scale-110 border-b border-transparent hover:border-gray-500 transition duration-150">
                Contact support <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </main>
        <div className="absolute inset-x-0 top-[calc(50%-9rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(50%-30rem)]" aria-hidden="true" >
            <div className=" animate-bounce relative left-[calc(10%+3rem)] aspect-[1155/678] w-[50.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#104afa] to-[#be04fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{clipPath:'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }} />
        </div>
      </div>
    )
  }
  