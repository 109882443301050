import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/20/solid";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import myConfig from "../configs/config";
import axios from "axios";
import { Description } from "@headlessui/react";
import AuthContext from "../context/AuthContext";
import Button from '@mui/material/Button';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, Skeleton, ThemeProvider } from '@mui/material';
import { Bounce, toast } from "react-toastify";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  bottom: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function Profile() {

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState(''); 
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('91');
  const [profileImage, setProfileImage] = useState('');
  const [isExpert, setisExpert] = useState(false);  
  const [coverImage, setCoverImage] = useState('');
  const [Description, setDescription] = useState('');
  const [jsonData, setJsonData] = useState({ linkedin: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isSaving, setIsSaving] = useState(false);  // New loading state for save button
  

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  
  useEffect(() => {
    getProfile();
  }, []);

  const { authTokens, logoutUser } = useContext(AuthContext);

  let getProfile = async () => {
    let response = await fetch(`${myConfig.CRU_URL}/account/profile/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authTokens.access}`
      },
    });
    let data = await response.json();
    if (data.code === "token_not_valid") {
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("access-token");
      window.location.href = "/login";
    }
    console.log(data);
    setProfile(data);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setEmail(data.email);
    setPhone(data.phone_number);
    setPreviewUrl(data.user_profile);
    if(data.is_educator ===true){
      console.log("This is educator");
      setisExpert(data.is_educator);
      setJsonData(data.json_data);
      setDescription(data.description);
      console.log("All Done");
    }else{
      setisExpert(false);
    };
    setLoading(false);
  };

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 60000);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const imageUrl = URL.createObjectURL(file);
      setPreviewUrl(imageUrl);
    }
  };

  let updateProfile = async () => {
    setIsSaving(true); // Start loading
    let formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('phone_number', phone);
    if (selectedFile) formData.append('user_profile', selectedFile);
    if (coverImage) formData.append('cover_image', coverImage);
    if (isExpert === true) {
      formData.append('description', Description);
      formData.append('json_data', JSON.stringify(jsonData));
    }
  
    try {
      let response = await fetch(`${myConfig.CRU_URL}/account/profile/`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: formData,
        signal: controller.signal
      });
  
      clearTimeout(timeoutId);
  
      // Check the response status code
      console.log(response);
      let data = await response.json();
      console.log('Data:', data);
      if (response.status === 200) {
        console.log('Status:', response.status);
  
        setProfile(data);
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setPhone(data.phone_number);
        
        if (data.is_educator === true) {
          setPreviewUrl(data.user_profile);
          setisExpert(data.is_educator);
          setJsonData(data.json_data);
        }
  
        toast.success('Profile Updated Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      } else if (response.status === 400) {
        Object.entries(data).forEach(([key, value]) => {
          value.forEach(errorMessage => {
            toast.error(`${key.toUpperCase()}: ${errorMessage}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            });
          });
        });
      } else if (response.status === 401) {
        // Handle unauthorized response
        logoutUser();
      } else {
        // Handle other unexpected statuses
        let data = await response.json();
        console.error('Unexpected response:', data);
      }
      
    } catch (error) {
      console.error('Error:', error);
  
      toast.error('Profile Update Error', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
  
      if (error.response && error.response.status === 401) {
        logoutUser();
      }
  
    } finally {
      setIsSaving(false); // Stop loading
      setLoading(false);
    }
  };
  

  return (
    <div className="w-full mx-auto bg-black py-20 px-14">
      <div className="md:flex mx-auto border w-fit border-gray-600 rounded-lg justify-center">
        <div className="md:shrink-0">
          <div className=" p-8">
            {loading ? (
              <Skeleton variant="circular" width={160} height={160} sx={{background:"gray"}} className="mx-auto" />
            ) : (
              previewUrl ? 
                <img src={previewUrl} className="w-80 h-80 mx-auto z-10 object-cover rounded-md" alt="User Profile"/> : 
                <UserIcon className="h-56 w-56 mx-auto text-gray-400 rounded-full" alt="User Profile"/>
            )}
          </div>
          {/* <div className="bg-white h-48 items-center text-center pt-8">
            {loading ? (
              <Skeleton variant="text" width={150} height={30} className="mx-auto" />
            ) : (
              <p className="text-black text-2xl font-bold">{firstName} {lastName}</p>
            )}
          </div> */}
        </div>
        <div className="p-14  shadow-md text-gray-200 ">
          <div className="uppercase tracking-wide text-sm font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 w-fit">Profile</div>
          <a href="#" className="block mt-1 text-md leading-tight font-medium text-gray-300 hover:underline">Change Your Profile Details</a>
            <form>
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                  <p className="mt-1 text-sm leading-6 ">
                    {loading ? (
                      <Skeleton variant="text" width={300} height={20} sx={{background:"gray"}}/>
                    ) : (
                      <>
                      {isExpert ? 
                        "Your Name and Profile Image will be visible to other users." : 
                        "Your Name and Profile Image will not be visible to other users until you interact with a course."}
                      </>
                    )}
                  </p>

                  <ThemeProvider theme={darkTheme}>
                    <div className="my-10 grid md:grid-cols-2 grid-cols-1 gap-5 ">
                      {loading ? (
                        Array.from({ length: 6 }).map((_, index) => (
                          <Skeleton key={index} variant="rectangular" width="100%" height={56} sx={{background:"gray"}} />
                        ))
                      ) : (<>
                          <TextField id="first-name" name="first-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} label="FIRST NAME" />
                          <TextField id="last-name" name="last-name" type="text" autoComplete="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} label="LAST NAME" />
                          <TextField value={phone} label="PHONE NUMBER" onChange={(e) => setPhone(e.target.value)} />
                          {/* <TextField id="linkedin" name="linkedin" type="text" value={{...jsonData.linkedin ? jsonData.linkedin: null}} onChange={(e) => setJsonData({ ...jsonData, linkedin: e.target.value })} label="LINKEDIN PROFILE" /> */}
                          <TextField id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="email" label="EMAIL" fullWidth />
                          <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<AddPhotoAlternateIcon />}>
                            Select Profile Photo
                            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFileChange}/>
                          </Button>
                        </>
                      )}
                    </div>
                      {isExpert ?
                        <div>
                          <h5 className="mb-4 text-transparent uppercase bg-clip-text bg-gradient-to-r from-blue-500 to-pink-500 w-fit">Expert Section </h5>
                          {/* <hr className="mb-4 border-gray-600" /> */}
                          <TextField id="text" name="text" value={Description} 
                          onChange={(e) => setDescription(e.target.value)} 
                          helperText={`${Description.length}/${500}`} 
                          inputProps={{
                            maxLength: 500
                          }}
                          label="Describe Yourself" fullWidth multiline
                          variant="standard"
                          rows={4}
                          />
                          
                        </div>
                        :<></>
                      }
                    
                  </ThemeProvider>
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <Button
                  variant="outlined"
                  onClick={updateProfile}
                  disabled={isSaving || loading} // Disable button while saving
                >
                  {isSaving ? <><CircularProgress size={24} /> Saving...</>: "Save"}
                </Button>
              </div>
            </form>

        </div>
      </div>
    </div>
  )
}
