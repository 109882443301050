import { Link } from "react-router-dom"
import {ReactTyped} from 'react-typed';

export function Services() {
    return (
        <div className="relative w-full bg-black">
          <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
          <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
          {/* <div className="absolute inset-0 -z-10 overflow-hidden">
              <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                  <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                  />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
              </svg>
          </div> */}
              
          <div className=" relative mx-auto max-w-2xl py-28 md:px-0 px-5 ">
              <div className=" relative">
                  <h4 className="font-bold text-gray-200 w-fit uppercase transition-transform duration-300 hover:scale-110 tracking-wide text-5xl hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                      Services
                  </h4>
                  <p className="animate-typing mt-6 text-lg leading-8 text-gray-300 font-mono">
                  <ReactTyped strings={
                    ["Whether you're looking for smart course , start your next journey with course of famous instructors -- we have a plan for you."]
                    } typeSpeed={50}  />
                  
                  </p>
                  
              </div>
          </div>
          <div className="mx-auto relative max-w-7xl grid md:grid-cols-2 gap-8 md:p-16 p-8 rounded-md">
            {/* Smart Contract Development */}
            <div  className="relative group border-gray-600 backdrop-blur-md rounded-lg border transition ease-in-out hover:scale-105 duration-300">
              <div className="absolute -inset-1.5  animate-gradient rounded-lg blur opacity-55 group-hover:opacity-100 bg-gradient-to-r from-blue-500 to-pink-500 transition duration-75 group-hover:duration-100 hidden group-hover:block	"></div>
              <div className="p-6 relative bg-black rounded-lg overflow-hidden">
                  <h4 className="font-semibold text-gray-200 text-2xl mb-4">Smart Contract Development</h4>
                  <p className="text-gray-200">
                    BlockchainPathshala offers smart contract development services, including:
                  </p>
                  <ul className="list-disc ml-5 mt-3 text-gray-400 space-y-2">
                    <li>DeFi smart contract development for lending platforms, exchanges, etc.</li>
                    <li>DAO smart contract development for decentralized organizations.</li>
                    <li>BEP20 token development for Binance Smart Chain applications.</li>
                    <li>ERC20 token development for Ethereum applications.</li>
                    <li>NFT platform development for digital collectibles.</li>
                    <li>Customized smart contract solutions for unique requirements.</li>
                  </ul>
                  <p className="text-gray-400 mt-4">
                    BlockchainPathshala’s experienced team ensures high-quality, tailored smart contract development for your needs.
                  </p>
                  <button className="mt-6 bsolute border-gray-600 border bottom-0 left-0 px-4 py-2 text-gray-200 rounded-sm  transition-colors duration-300">
                    Contact Us for Development
                  </button>
              </div>
            </div>

            {/* Smart Contract Auditing */}
            <div  className="relative group border-gray-600 rounded-lg border transition ease-in-out hover:scale-105 duration-300">
              <div className="absolute -inset-1.5 animate-gradient rounded-sm blur opacity-55 group-hover:opacity-100 hidden group-hover:block	bg-gradient-to-r from-blue-500 to-pink-500"></div>
              <div className="p-6 relative bg-black  backdrop-blur-md rounded-lg overflow-hidden  h-full">
                <h4 className="font-semibold text-gray-100 text-2xl mb-4">Smart Contract Auditing</h4>
                <p className="text-gray-200">
                  BlockchainPathshala provides a range of smart contract auditing services:
                </p>
                <ul className="list-disc ml-5 mt-3 text-gray-400 space-y-2">
                  <li>Security audits to identify vulnerabilities.</li>
                  <li>Code review for correctness and efficiency.</li>
                  <li>Functionality and performance testing.</li>
                  <li>Comprehensive documentation for clarity.</li>
                </ul>
                <p className="text-gray-400 mt-4">
                  Trust BlockchainPathshala’s skilled auditors for reliable, cost-effective smart contract auditing.
                </p>
                <button className="mt-auto absolute border-gray-600 border  bottom-5  px-4 py-2  text-gray-300 rounded-sm  transition-colors duration-300 ">
                  Contact Us for Auditing
                </button>
              </div>
            </div>
          </div>

        </div>
    )
}