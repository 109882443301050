import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserIcon } from "@heroicons/react/20/solid";
import { AcademicCapIcon } from "@heroicons/react/24/outline";
import { jwtDecode } from "jwt-decode";
import myConfig  from "../configs/config"
import AuthContext from "../context/AuthContext";
import firstblog from "../assests/images/firstblog.png"
import second from "../assests/images/second.png"
import lastblog from "../assests/images/lastblog.png"

import proA from "../assests/experts/proA.png"
import sahil from "../assests/experts/sahil.png"
import rohan from "../assests/experts/rohan.png"
import {ReactTyped} from 'react-typed';

export function OofBlogs() {
    
    return(
        <div div className="relative bg-black">
            {/* <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true"
                >
                <defs>
                    <pattern
                    id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                    width={200}
                    height={200}
                    x="10%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                    >
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="10%" y={-1} className="overflow-visible fill-blue-50">
                    <path
                    d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                    strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div> */}
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            
            <div className=" relative mx-auto max-w-2xl py-28 md:px-0 px-4 bg-transparent">
                <div className="">
                    <h4 className="font-bold uppercase w-fit transition-transform duration-300 hover:scale-110 tracking-wide text-gray-200 text-5xl hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                        Blogs
                    </h4>
                    <p className="animate-typing mt-6 text-lg leading-8 text-gray-300 font-mono">
                        <ReactTyped strings={
                        [
                            "In the fast-paced world of technology, there's a term that has been making waves..",
                            "In recent years, you might have heard the term 'Web3' buzzing around in tech circles...",
                            "The internet has come a long way since its inception. Over the years, it has gone..."
                        ]
                        } typeSpeed={50} loop  />
                        
                    </p>
                    
                </div>
            </div>
            <div className="md:flex mx-auto relative ">
                
                <div className="md:basis-8/12  md:px-16 px-5 backdrop-blur-xl">
                    <div className="">
                        {[{
                            image: firstblog,
                            title: "The Blockchain Revolution: Unraveling the Future of Trust",
                            author: "Sahil Shaikh",
                            description: "In the fast-paced world of technology, there's a term that has been making waves...",
                            link: `/sahil-shaikh/the_blockchain_revolution`,
                            authorImage:sahil
                        }, {
                            image: second,
                            title: "Web3: The Future of the Internet - Decentralization",
                            author: "Rohan Ramteke",
                            description: "In recent years, you might have heard the term 'Web3' buzzing around in tech circles...",
                            link: `/rohan/web3`,
                            authorImage:rohan
                        }, {
                            image: lastblog,
                            title: "The Evolution of the Internet: Web 1, Web 2, and Web3",
                            author: "Prof. Aishwarya",
                            description: "The internet has come a long way since its inception. Over the years, it has gone...",
                            link: `/Aishwarya/evolution_of_internet`,
                            authorImage:proA
                        }].map((blog, index) => (
                        <div key={index} className="group md:mb-10 mb-8 rounded-sm md:p-8 border border-gray-700 bg-transparent transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl">
                            <div className="w-full overflow-hidden  rounded-sm">
                                <img
                                    className="object-fit w-full h-72 rounded-sm transition-transform duration-300 hover:scale-110"
                                    src={blog.image}
                                    alt="Blog"
                                />
                            </div>
                            <div className="p-8 md:p-3">
                                <div className="flex items-center gap-x-4 mb-4">
                                    <div className="md:w-12 md:h-12 rounded-full  flex justify-center items-center overflow-hidden">
                                        <img className="md:h-12 md:w-12 rounded-full " src={blog.authorImage} alt="Author" />
                                    </div>
                                    <div>
                                    <h2 className="text-xl md:text-2xl font-bold text-gray-200">{blog.title}</h2>
                                    <div className="text-sm text-gray-200">Author: <span className="font-semibold">{blog.author}</span></div>
                                    </div>
                                </div>
                                <p className="pb-5 text-gray-300 leading-relaxed">{blog.description}</p>
                                <Link
                                    to={blog.link}
                                    className="px-4 py-2 rounded-sm border border-gray-600 text-gray-300 uppercase transition-transform duration-300 transform  hover:scale-105 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
                <div className="md:basis-4/12 mx-auto rounded-sm">
                    <div className="border border-gray-600 p-8 rounded-md">
                        <div className="flex justify-between items-center">
                            <h1 className="text-2xl font-semibold text-gray-100">TOP BLOGS</h1>
                        </div>
                
                        <div className="mt-8 space-y-10">
                            {[{
                                image: lastblog,
                                title: "The Evolution of the Internet: Web 1, Web 2, and Web3",
                                author: "Prof. Aishwarya",
                                description: "The internet has come a long way since its inception. Over the years, it has gone...",
                                link: `/Aishwarya/evolution_of_internet`,
                                authorImage:proA
                            },{
                                image: firstblog,
                                title: "The Blockchain Revolution: Unraveling the Future of Trust",
                                author: "Sahil Shaikh",
                                description: "In the fast-paced world of technology, there's a term that has been making waves...",
                                link: `/sahil-shaikh/the_blockchain_revolution`,
                                authorImage:sahil
                            },{
                                image: second,
                                title: "Web3: The Future of the Internet - Decentralization",
                                author: "Rohan Ramteke",
                                description: "In recent years, you might have heard the term 'Web3' buzzing around in tech circles...",
                                link: `/rohan/web3`,
                                authorImage:rohan
                            },].map((blog, index) => (
                            <div key={index} className=" border group border-gray-600 rounded-sm md:p-5 transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl">

                                <div className="p-8 md:p-3">
                                    <div className="flex items-center gap-x-4 mb-4">
                                        <div>
                                        <Link to={blog.link} className="text-xl font-bold text-gray-200 group-hover:bg-clip-text group-hover:text-transparent group-hover:bg-gradient-to-r group-hover:from-blue-500 group-hover:to-pink-500">{blog.title}</Link>
                                        <p className="text-sm text-gray-400">Author: <span className="font-bold">{blog.author}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}