import { Navigate, Route } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
    let { user } = useContext(AuthContext);
    console.log(user);
    if (!user) {
        return <Navigate to="/login" replace />
    }
    return children;
};

export default PrivateRoute;