import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { UserIcon } from "@heroicons/react/20/solid";
import AuthContext from "../context/AuthContext";
import myConfig from "../configs/config";
import { Skeleton } from "@mui/material";

export function ReadBlog() {
    const [blogs, setBlogs] = useState(null); // Default to null to differentiate between loading and empty state
    const { authTokens, user, logoutUser } = useContext(AuthContext);
    const { BlogId } = useParams();

    useEffect(() => {
        const getBlog = async () => {
            console.log("Getting individual blog", BlogId);
            const blogUrl = `${myConfig.CRU_URL}/account/blog/${BlogId}/`;
            try {
                const response = await axios.get(blogUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authTokens.access}`,
                    },
                });
                console.log(response.data);
                setBlogs(response.data);
            } catch (error) {
                console.error("Error fetching blogs", error);
            }
        };

        if (authTokens && BlogId) { // Check that authTokens and BlogId are available
            getBlog();
        }else{
            logoutUser();
        }
    }, [authTokens, BlogId]); // Add BlogId to the dependency array

    if (!blogs) {
        return (
            <div className="flex relative justify-center items-center bg-black overflow-y-scroll h-screen">
                <div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
                <div className="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>

                <div className="w-3/4 relative p-10 my-10 rounded-lg backdrop-blur-sm border border-gray-600">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-x-4">
                        <Skeleton variant="circular" width={48} height={48} sx={{background:"gray"}} />
                        <div className="w-full">
                            <Skeleton width="100%" height={24} sx={{background:"gray"}}/>
                            <Skeleton width="40%" height={20} sx={{background:"gray"}}/>
                        </div>
                        </div>
                        {/* <Skeleton width={80} height={40} sx={{background:"gray"}}/> */}
                    </div>
                    <div className="mt-4">
                        <Skeleton variant="rectangular" height={200} sx={{background:"gray"}}/>
                    </div>
                    <div className="mt-4 items-center justify-center text-gray-300">
                        <Skeleton width="100%" height={100} sx={{background:"gray"}}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex relative justify-center items-center bg-black h-screen">
            <div class="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:25px_24px]"></div>
            <div class="absolute left-0 right-0 top-0 h-full w-full rounded-3xl bg-[radial-gradient(circle_400px_at_50%_300px,#fbfbfb36,#000)]"></div>
            <div className="w-3/4 relative p-10 my-10 rounded-lg backdrop-blur-sm border border-gray-600">
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-x-4">
                        <div className="w-12 h-12 rounded-full bg-gray-800 flex justify-center items-center overflow-hidden">
                            {blogs.user.user_profile ? (
                                <img className="w-12 h-12" src={`${blogs.user.user_profile}`} alt="User profile" />
                            ) : (
                                <UserIcon className="h-6 w-auto text-gray-200" />
                            )}
                        </div>
                        <div>
                            <h1 className="text-lg font-bold text-gray-200">{blogs.title}</h1>
                            <p className="text-xs text-gray-200">
                                Author: <span className="font-bold">{blogs.user.first_name} {blogs.user.last_name}</span>
                            </p>
                        </div>
                    </div>
                    <div>
                        {user.user_id === blogs.user.id ? 
                        <Link to="/createblog" className="flex items-center group gap-x-2 bg-transparent text-gray-200 px-3 uppercase py-2 rounded-md border border-gray-600 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-blue-500 hover:to-pink-500">
                            <UserIcon className="w-6 text-gray-200 group-hover:text-blue-500 " />
                            <span>Edit Blog</span>
                        </Link>
                        :<></> }
                    </div>
                </div>
                <div className="mt-4 relative">
                    <img src={`${blogs.image}`} alt="blog" className="h-56 w-full object-fill rounded-lg" />
                </div>
                <div className="mt-4 items-center justify-center text-gray-300" dangerouslySetInnerHTML={{ __html: blogs.description }}>
                    {/* {} */}
                </div>
            </div>
        </div>
    );
}
